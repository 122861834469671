import { AsyncTypeahead } from "react-bootstrap-typeahead";
import 'react-bootstrap-typeahead/css/Typeahead.css'
import { useAppSelector } from "../../hooks";
import { useState } from "react";

export default function BaseSearch(props: any) {
    const isLoading = useAppSelector(state => state.api.isLoading);
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    // function onClear(input:any) {
    //     if(!input) {
    //         props.attributes.onChangeAttr('')
    //     }
    // }
    function handleMouseEnter() {
        setTooltipVisible(true);
    }

    function handleMouseLeave() {
        setTooltipVisible(false);
    }
    const filterBy = () => true;

    return (<>
        <AsyncTypeahead
            filterBy={filterBy}
            id="async-example"
            isLoading={isLoading}
            labelKey="name"
            minLength={3}
            onSearch={props.handleSearch}
            onChange={props.handleSelect}
            options={props.options}
            className={props.className || 'col-xs-8'}
            placeholder={props.placeholder}
            selected={props.selected}
            caseSensitive={false}
            defaultInputValue={props.value}
            renderMenuItemChildren={(option: any) => (
                <div key={option.id}>
                    <p>{option.id != undefined ? option.name : option}</p>
                </div>
            )}
            renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
                <div className={`form-group ${props.error ? "has-error" : ""} full-width d-flex flex-column mb-0`}>
                <input type="text" value={inputProps.value} onChange={inputProps.onChange} className={inputProps.className} name="textinput"/>
                {props.error && (
                    <>
                        <div
                            className={`vismaicon vismaicon-sm vismaicon-filled vismaicon-error`}
                            onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                        >
                            {isTooltipVisible && (
                                <div className="tooltip tooltip-error in right left-16 top-n16">
                                    <div className="tooltip-arrow"></div>
                                    <div className="tooltip-inner text-nowrap">{props.error}</div>
                                </div>
                            )}
                        </div>
                    </>
                )}
                </div>
              )}
        />
    </>
    );
}