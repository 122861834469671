import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserModel } from "./vonReducer";
import { MigrationStatus, SourceType } from "../Components/Enums/CommonEnums";

export interface Migration {
    id: string,
    customerName : string,
    customerId : string,
    organizationNumber : string,
    status : MigrationStatus,
    owner : any,
    assignedTo : any,
    changed : string,
    created : string,
    type : string,
    customerNumber : string,
    aoMigration: any,
    additionalInformation: number,
    warnings: [],
    eAccountingVariant: number,
    sourceType: SourceType
}

export interface AdditionalInfo {
    dataType: string,
    identifier: string,
    required: boolean,
    key: string,
    value: string,
    //Status: string
    name: string,
    keyName: string,
    error: string
}

export interface MigrationAdditionalInfo {
    id: string,
    customerName: string,
    organizationNumber: string,
    additionalInformations: AdditionalInfo[],
    readonly: boolean
}

export interface MigrationState {
    migrationList: Array<Migration>,
    aoUsers: Array<UserModel>,
    migrationAdditionalInfo: MigrationAdditionalInfo | null,
    migrationDetails: Migration | null,
}

const initialState : MigrationState = { migrationList: [], aoUsers: [], migrationAdditionalInfo: null, migrationDetails: null};

export const migrationSlice = createSlice({
    name: 'migration',
    initialState,
    reducers: {
        storeMigrations: (state, action:PayloadAction<any>) => {
            state.migrationList = action.payload;
        },
        storeMigrationDetails: (state, action:PayloadAction<any>) => {
            state.migrationDetails = action.payload;
        },
        storeAoUsers: (state, action: PayloadAction<UserModel[]>) => {
            state.aoUsers = action.payload;
        },
        storeMigrationForAdditionalInfo: (state, action: PayloadAction<MigrationAdditionalInfo>) => {
            state.migrationAdditionalInfo = action.payload
        },
        setAdditionalValue: (state, action: PayloadAction<AdditionalInfo>) => {
            const p = action.payload;
            const item = state.migrationAdditionalInfo?.additionalInformations.find(i => i.dataType === p.dataType && i.identifier === p.identifier && i.key === p.key)
            if (item)
                item.value = p.value
        }
    },
});

export const {storeMigrations,
    storeAoUsers,
    storeMigrationForAdditionalInfo,
    setAdditionalValue,
    storeMigrationDetails,
} = migrationSlice.actions;

export default migrationSlice.reducer;
