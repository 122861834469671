import React, {useState} from 'react';
import ScrollToTopArrow from '../Icons/ScrollToTopArrow';

export default function ScrollButton(){

    const [visible, setVisible] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 300){
            setVisible(true)
        }
        else if (scrolled <= 300){
            setVisible(false)
        }
    };

    const scrollToTop = () =>{
        window.scrollTo({
            top: 0,
            behavior: 'auto'
        });
    };

    window.addEventListener('scroll', toggleVisible);

    return (
        <>
            <div onClick={scrollToTop} className={`scroll-button-container ${visible ? "scroll-button-visible" : ""}`}>
                <ScrollToTopArrow />
            </div>
        </>
    );
}
