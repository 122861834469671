//Functions for converting JSON to CSV and CSV to JSON

export const jsonToCsv = (json: any[]): string => {
    if (json.length === 0) {
        return '';
    }

    const headers = Object.keys(json[0]);
    const csv = [headers.join(';')];

    for (const row of json) {
        const values = headers.map(header => row[header]);
        csv.push(values.join(';'));
    }

    return csv.join('\n');
}

export const csvToJson = (csv: string): any[] => {
    const lines = csv.split('\n');
    const headers = lines[0].split(';');
    const json: any[] = [];

    for (let i = 1; i < lines.length; i++) {
        const obj: any = {};
        const currentline = lines[i].split(';');

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }

        json.push(obj);
    }

    return json;
}
