import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ApiState {
    isLoading: boolean,
    error: string | null,
    data: string | null,
    isDownloading: boolean,
    progress: IProgress | null
}

export interface IProgress {
    receivedLength: number,
    contentLength: number,
    percent: number,
    fileName: string,
}

export class Progress implements IProgress {

    contentLength: number;
    receivedLength: number;

    constructor(contentLength : number, receivedLength: number, filename: string) {
        this.contentLength = contentLength;
        this.receivedLength = receivedLength;
        this.fileName = filename;
        this.percent = Math.round(receivedLength / contentLength * 100);
    }

    percent: number;
    fileName: string;
}

const initialState: ApiState = {
    isLoading: false,
    error: null,
    data: null,
    isDownloading: false,
    progress: null
};

export const apiSlice = createSlice({
    name: 'api',
    initialState,
    reducers: {
        setIsLoading: (state, action: PayloadAction<boolean>) => {
            state.isLoading = action.payload
        },
        storeData: (state, action: PayloadAction<string | null>) => {
            state.data = action.payload
        },
        isDownloading: (state, action: PayloadAction<boolean>) => {
            state.isDownloading = action.payload

            if(!action.payload)
                state.progress = null;
        },
        downloadProgress: (state, action: PayloadAction<Progress>) => {
            state.progress = action.payload;
        }
    },
});

export const {setIsLoading, storeData, isDownloading, downloadProgress } = apiSlice.actions;
export default apiSlice.reducer;
