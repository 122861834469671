import {useAppSelector} from "../hooks";
import { UserModel } from "../Reducers/vonReducer";
import { useUrlHelper } from "../Constants/urlHelper";

export default function MigrationVon(props:any) {
    const vonUsers = useAppSelector(state => state.von.vonUsers);

    const urlHelper = useUrlHelper();

    return (
        <div className="panel-body">
            <div className="d-flex mb-24">
                <div className="col-lg-6 ">
                    <h3 className="font-weight-bold">Migrerande VON användare</h3>
                    <span className="d-flex gap">
                        <span>
                            <h4 className="text-primary font-weight-bold">Namn</h4>
                            <p>{props.migration.owner.name}</p>
                        </span>
                        <span>
                            <h4 className="text-primary font-weight-bold">Mailadress</h4>
                            <p>{props.migration.owner.email}</p>
                        </span>
                    </span>
                </div>
                <div className="col-lg-6">
                    <div className="btn-group btn-group-justified">
                        <a className="btn" onClick={ urlHelper.startMyServices }>VON mina tjänster</a>
                        <a className="btn" onClick={() => urlHelper.openInternalCustomeradmin(props.migration.customerNumber)}>VON intern kundadmin</a>
                    </div>
                </div>
            </div>
            <div>
                <h3 className="font-weight-bold">VON användare på företaget</h3>
                <table className="table table-stripe">
                    <thead>
                    <tr>
                        <th>Namn</th>
                        <th>Email</th>
                        <th style={{ width: "20rem"}}>VON admin</th>
                        <th style={{ width: "20rem"}}>eEkonomi användare</th>
                        <th style={{ width: "20rem"}}>Konsult</th>
                    </tr>
                    </thead>
                    <tbody>
                    {vonUsers && vonUsers.map((user:UserModel, index:number) =>
                        <tr key={index} >
                            <td>{user.fullName}</td>
                            <td>{user.email}</td>
                            <td>
                                {user.isAdministrator && <i className="vismaicon vismaicon-filled vismaicon-success"></i>}
                                {!user.isActive && <i>Inactive</i>}
                            </td>
                            <td>{user.isEaccountingUser && <i className="vismaicon vismaicon-filled vismaicon-success"></i>}</td>
                            <td>{user.isConsultant && <i className="vismaicon vismaicon-web"></i>}</td>
                        </tr>
                    )}
                    </tbody>
                </table>
            </div>
        </div>
    );
}