import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { store } from './app/store';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import { AuthProvider } from './context/AuthProvider';
import { SetBaseUri} from './Utils/httpUtils';
import { SetIdentityServer } from "./app/VismaConnect";

SetBaseUri();
SetIdentityServer();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

let htmlElement = document.querySelector("html") as HTMLElement;
const darkMode = localStorage.getItem("dark-mode");

if (!!darkMode && darkMode !== 'light-theme')
    htmlElement.classList.add("dark-theme");
else
    htmlElement.classList.add("light-theme");