import { startGuideDropdowns } from "../../Constants/dropdownLists";
import { useAppSelector } from "../../hooks";

export default function ConfirmInformation(props: any) {
    const { startGuide } = props;
    const customerInfo = startGuide.customerInfo;
    const migration = useAppSelector(state => state.migration).migrationDetails

    const FormGroup = (props: any) => {
        return (
            <div className="form-group full-width d-flex flex-row mb-0">
                <span className="col-md-8">
                    <label className="col-md-4 control-label bold">{props.label}</label>
                </span>
                <div className="col-md-10">
                    <label className="col-md-10 control-label">{props.value}</label>
                </div>
            </div>
        );
    };

    const findDropdownValue = (array: any[], id: string) => {
        const selectedItem = array.find((item) => item.id === parseInt(id) || parseInt(item.name) === parseInt(id));
        return selectedItem ? selectedItem.name : "";
    };

    if(!migration)
        return null;

    return (
        <div className="d-flex justify-content-around py-32 flex-row">
            <div style={{ width: "80%" }}>
                <div className="panel-heading py-0">
                    <h5>Kunduppgifter</h5>
                </div>
                <div className="panel-body">
                    {/* <FormGroup
                        label="Telefonnummer"
                        value={customerInfo.phoneNumber}
                    /> */}
                    <FormGroup
                        label="Primärt företagskonto"
                        value={findDropdownValue(startGuideDropdowns.bookingAccount, customerInfo.bookingAccount)}
                    />
                    <FormGroup
                        label="Bokföringsmetod"
                        value={findDropdownValue(startGuideDropdowns.bookKeepingMethod, customerInfo.bookKeepingMethod)}
                    />
                    <FormGroup
                        label="Momsperiod"
                        value={findDropdownValue(startGuideDropdowns.vatPeriod, customerInfo.vatPeriod)}
                    />
                    <FormGroup
                        label="Bank"
                        value={customerInfo.bank.name}
                    />
                    {!(migration.eAccountingVariant > 0) && <FormGroup
                        label="Vald produkt"
                        value={findDropdownValue(startGuideDropdowns.smbProduct, startGuide.product.id) + ", " + startGuide.product.price + "/mån. " + startGuide.product.period + " månaders fakturering"}
                    />}
                </div>
            </div>
        </div>
    );
}
