import { useAppSelector } from "../hooks";
import { ApiState } from "../Reducers/apiReducer";

export function DownloadProgress() {

    const apiState: ApiState = useAppSelector((state) => state.api);
    const progress = apiState.progress;
    return (
        <>
            {apiState.isDownloading && progress &&
                <div className="progressContainer">
                    <h3>Laddar ner fil</h3>
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" aria-valuenow={progress.percent}
                             aria-valuemin={0} aria-valuemax={100} style={{width: `${progress.percent}%`}}>
                            <span className="percentage">{progress.percent}%</span>
                        </div>
                        <span className="progress-label">{progress.fileName}</span>
                    </div>
                </div>
            }
        </>
    );

}