import React, { useState } from "react";
import './CSS/TextInput.css';

export default function TextInput(props: any) {
    const [isTooltipVisible, setTooltipVisible] = useState(false);

    function getIcon() {
        if (props.icon) {
            if (props.iconClick) {
                return <span className={`${props.icon} label-icon`} style={{ cursor: "pointer" }} onClick={props.iconClick} aria-hidden="true"></span>
            }
            if (props.iconHover) {
                return <span className={`${props.icon} label-icon`} style={{ cursor: "help" }} data-title={props.iconHover} aria-hidden="true"></span>
            }
            return <span className={`${props.icon} label-icon`} aria-hidden="true"></span>
        }
    }

    function handleMouseEnter() {
        setTooltipVisible(true);
    }

    function handleMouseLeave() {
        setTooltipVisible(false);
    }

    function renderError() {
        if (props.error) {
            return (
                <>
                    <div
                        className={`vismaicon vismaicon-sm vismaicon-filled vismaicon-error`}
                        onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}
                    >
                        {isTooltipVisible && (
                            <div className="tooltip tooltip-error in right left-16 top-n16">
                                <div className="tooltip-arrow"></div>
                                <div className="tooltip-inner text-nowrap">{props.error}</div>
                            </div>
                        )}
                    </div>
                </>
            )
        }
    }



    let additionalInfo = null;
    if (props.maxAmountOfCharacters && !props.hideAdditionalInfo) {
        let charactersLeft = props.maxAmountOfCharacters - props.value.length;
        additionalInfo = <p className="form-text text-muted">Maximum amount of characters: {charactersLeft}</p>;
        if (charactersLeft !== props.maxAmountOfCharacters)
            additionalInfo = <p className="form-text text-muted">Amount of characters left to use: {charactersLeft}</p>;
        if (charactersLeft < 0)
            additionalInfo = <p className="form-text text-muted">You have exceeded the maximum amount of characters</p>;
    }

    return (
        <div className={`form-group ${props.error ? "has-error" : ""} full-width ${props.alignment} d-flex flex-column`}>
            <span className={`col-md-${props.elementWidth ? props.elementWidth : 6} col-xs-10`}>
                {props.label && <label className={`col-md-${props.labelWidth ? props.labelWidth : 4} control-label col-xs-6`}>{props.label}{getIcon()}</label>}
            </span>
            {!props.multiline && <div className={`col-md-${props.elementWidth ? props.elementWidth : 6} col-xs-10  ${props.padding ? props.padding : ""}`}>
                <input autoComplete={props.noAutoComplete ? "off" : "on"} onChange={(e) => props.onChange(e.target.value, props.field)} type="text"
                    className="form-control" placeholder={props.placeholder} name="textinput" value={props.value} disabled={props.disabled} />
                {renderError()}
            </div>}
            {props.multiline && <div className={`col-md-${props.elementWidth ? props.elementWidth : 6} col-xs-10`}>
                <textarea onChange={(e) => props.onChange(e.target.value, props.field)}
                    className="form-control" placeholder={props.placeholder} name="textarea" value={props.value} disabled={props.disabled} />
                {additionalInfo}
            </div>}

        </div>
    )
}