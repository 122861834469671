import TextInput from "./Helpers/TextInput";
import { useState } from "react";
import { useHttp } from "../../Utils/httpUtils";
import { showAlert } from "../Reducers/alertReducer";
import { useAppDispatch } from "../hooks";

export default function CreateTestMigration() {

    const [data, setData] = useState({companyName: "", customerNumber: 0});

    const { request } = useHttp();
    const dispatch  = useAppDispatch();

    function onSave() {

        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(data)
        }

        request('/api/admin/CreateTestMigration', httpRequest, (data: any) => {

            dispatch(showAlert({
                message: `Företaget har skapats med organisationsnummer: ${data.organizationNumber}`,
                title: `Lyckats`,
                style: "top",
                type: "success"
            }))
        });
    }

    return <>
        <div className="panel">
            <div className="panel-body ">
                <h2>Skapa testföretag</h2>

                <TextInput label="Företagsnamn"
                           onChange={(value: any) => setData({
                               ...data,
                               companyName: value
                           })} />

                <TextInput label="Kundnummer för byrån"
                           onChange={(value: number) => setData({
                               ...data,
                               customerNumber: value
                           })}
                />

                <button type="button" className="btn btn-primary" disabled={data.companyName.length < 3 || data.customerNumber.toString().length < 7} onClick={() => onSave()}>Spara</button>
            </div>
        </div>
    </>;

}