import TextInput from "./Helpers/TextInput";
import Modal from "./Helpers/Modal";
import { setFieldValue } from "../Reducers/formsReducer";
import { useAppDispatch, useAppSelector } from "../hooks";
import DropDown from "./Helpers/Dropdown";
import { useEffect, useState } from "react";
import { userRoles } from "../Constants/dropdownLists";
import { useUserActionCreator } from "../Actions/UserActionCreator";
import { User, UserRole } from "../Reducers/userReducer";

export default function EditUsersModal() {
    const dispatch = useAppDispatch();
    const userActions = useUserActionCreator();
    const form = useAppSelector((state) => state.forms.forms['userForm'].data);
    const [roles] = useState(userRoles);
    const users = useAppSelector((state) => state.users.userList);
    const [userEditing, setUserEdit] = useState("");


    useEffect(() => {
        userActions.getUsers()
    }, [])


    function onSave(){
        if(!(form["email"].trim() === "" || form["role"].trim() === ""))
        userActions.saveUser(form)
    }

    function handleFieldChange(value: any, id: any) {
        dispatch(setFieldValue({ form: "userForm", field: id, value: value }))
    }

    const editUserRole = (role: UserRole, userId : string) => {
        userActions.updateUserRole(role, userId)
        setUserEdit("");
    };

    const getRoleName = (role : UserRole) => UserRole[role];

    const deleteUser = (user : User) => {
        userActions.deleteUser(user)
    };

    return(
      <><Modal title="Redigera användare" size={"modal-lg"} onSave={onSave}>
          <form className="form-horizontal" >
              <table className="table table-stripe">
                  <thead>
                    <tr>
                        <th>Mailadress</th>
                        <th>Roll</th>
                        <th></th>
                    </tr>
                  </thead>
                  <tbody>
                  {users.map((user: User, index) => {
                      return <tr key={index}>
                          <td>{user.email}</td>
                          <td>{userEditing === user.id ?
                              <div className="user-role-container">
                                  <div className="role-edit">
                                      <DropDown value={user.role}
                                                onChange={(role: UserRole) => editUserRole(role, user.id)} list={roles}
                                                elementWidth="12"></DropDown></div>
                                  <i onClick={() => setUserEdit("")}
                                     className="vismaicon vismaicon-edit edit-role-icon"></i></div> :
                              <div className="user-role-container">{getRoleName(user.role)}<i
                                  onClick={() => setUserEdit(user.id)}
                                  className="vismaicon vismaicon-edit edit-role-icon"></i></div>}</td>
                          <th onClick={() => deleteUser(user)}><i className="vismaicon vismaicon-delete"></i></th>
                      </tr>;
                    })}
                    <tr>
                        <td><div className="role-edit"><TextInput elementWidth="12" onChange={handleFieldChange} field={"email"} value={form["email"]} placeholder="Mailadress" /></div></td>
                        <td><div className="role-edit"><DropDown onChange={(value: any) => handleFieldChange(value, "role")} list={roles} blankOption="true" elementWidth="12"></DropDown></div></td>
                        <td></td>
                    </tr>
                  </tbody>
              </table>
          </form>
      </Modal></>
    );
}