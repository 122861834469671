import DropDown from "../Helpers/Dropdown";
import { startGuideDropdowns } from "../../Constants/dropdownLists";
import TextInput from "../Helpers/TextInput";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { setSMBCustomerInfoValue } from '../../Reducers/smbStartGuidReducer';
import BaseSearch from "../Helpers/BaseSearch";
import { useMigrationActionCreator } from "../../Actions/MigrationActionCreator";
import { useEffect } from "react";

export default function CustomerInfo(props: any) {
    const { customerInfo, validationErrors } = props;
    const migrationActions = useMigrationActionCreator();
    const bankListState = useAppSelector(state => state.bankList.bankList);

    const dispatch = useAppDispatch();

    useEffect(() => {
        migrationActions.getAllBanks();
    }, [])

    const onFieldChange = (field: any, value: any) => {
        dispatch(setSMBCustomerInfoValue({ field: field, value: value }));
    };

    const renderAsterisk = (fieldName: string) => {
        if (validationErrors && validationErrors[fieldName]) {
            return <span style={{ color: 'red' }}>*</span>;
        }
        return null;
    };

    function getBankValue(id:any){
        return bankListState.find(x => x.id === id);
    }

    return (
        <div className="d-flex justify-content-around py-32 flex-row">
            <div style={{ width: "60%" }}>
                <div>
                    <p className="m-0">
                        {renderAsterisk("bookingAccount")}
                        Vilket bokföringskonto används som företagskonto?
                    </p>
                    <DropDown
                        list={startGuideDropdowns.bookingAccount}
                        elementWidth="12"
                        padding="p-0"
                        blankValue="Välj"
                        blankOption={true}
                        onChange={(value: any) => onFieldChange("bookingAccount", Number(value))}
                        nameValued={true}
                        value={customerInfo.bookingAccount}
                        error={validationErrors.bookingAccount}
                    />
                </div>
                <div>
                    <p className="m-0">
                        {renderAsterisk("bookKeepingMethod")}
                        Bokföringsmetod
                    </p>
                    <DropDown
                        list={startGuideDropdowns.bookKeepingMethod}
                        elementWidth="12"
                        padding="p-0"
                        blankValue="Välj"
                        blankOption={true}
                        onChange={(value: any) => onFieldChange("bookKeepingMethod", Number(value))}
                        value={customerInfo.bookKeepingMethod}
                        error={validationErrors.bookKeepingMethod}
                    />
                </div>
                <div>
                    <p className="m-0">
                        {renderAsterisk("vatPeriod")}
                        Momsperiod
                    </p>
                    <DropDown
                        list={startGuideDropdowns.vatPeriod}
                        elementWidth="12"
                        padding="p-0"
                        blankValue="Välj"
                        blankOption={true}
                        onChange={(value: any) => onFieldChange("vatPeriod", value)}
                        value={customerInfo.vatPeriod}
                        error={validationErrors.vatPeriod}
                    />
                </div>
                <div>
                    <p className="m-0">
                        {renderAsterisk("bank")}
                        Bank
                    </p>
                    <DropDown list={bankListState}
                        elementWidth="12"
                        padding="p-0"
                        blankValue="Välj"
                        value={customerInfo.bank?.id}
                        onChange={(value: any) => onFieldChange("bank", getBankValue(value))}
                        blankOption={true}
                        error={validationErrors.bank}/>
                </div>
            </div>
        </div>
    );
}
