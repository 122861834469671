import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface PaginationReducer {
    currentRows: number,
    offset: number,
    totalRowCount: number,
    sortChange: boolean
}

const initialState: PaginationReducer = {
    currentRows: 25,
    offset: 0,
    totalRowCount: 100,
    sortChange: false
}

export const paginationSlice = createSlice({
    name: "pagination",
    initialState,
    reducers: {
        setRowCount: (state, action: PayloadAction<number>) => {
            state.currentRows = action.payload;
        },
        setOffset: (state, action:PayloadAction<number>) => {
            state.offset = action.payload;
        },
        setTotalRowCount: (state, action:PayloadAction<number>) => {
            state.totalRowCount = action.payload;
        },
        setSortChange: (state, action:PayloadAction<boolean>) => {
            state.sortChange = action.payload;
        }
    }
});

export const {setRowCount, setOffset, setTotalRowCount, setSortChange} = paginationSlice.actions;
export default paginationSlice.reducer;