export enum LogType {
    Warning = 0,
    Error = 1,
    Info = 2
}

export enum SendStatus {
    None = 0,
    Company = 10,
    BookkeepingAccounts = 15,
    Articles = 20,
    ArticleLabels = 21,
    Customers = 30,
    CustomerLabels = 31,
    Suppliers = 40,
    DeliveryMethods = 50,
    DeliveryTerms = 60,
    CustomerLedgerItems = 70,
    SupplierLedgerItems = 80,
    SieFiles = 90,
    Attachments = 91,
    Done = 100,
}