import { configureStore, ThunkAction, Action, combineReducers } from '@reduxjs/toolkit';
import api from "./Reducers/apiReducer";
import modal from "./Reducers/modalReducer";
import aoMigration from "./Reducers/aoReducer";
import migration from "./Reducers/migrationReducer";
import dropdown from "./Reducers/dropdownReducer";
import forms from "./Reducers/formsReducer";
import search from "./Reducers/searchReducer"
import startGuide from "./Reducers/startguideReducer";
import smbStartGuide from './Reducers/smbStartGuidReducer';
import bankList from "./Reducers/bankListReducer";
import users from "./Reducers/userReducer"
import von from "./Reducers/vonReducer";
import internalMigration from "./Reducers/internalMigrationReducer";
import alertReducer from './Reducers/alertReducer';
import productInformation from './Reducers/productInformationReducer';
import pagination from './Reducers/paginationReducer';
import admin from './Reducers/adminReducer';
import fortnox from './Reducers/fortnoxReducer';
import fileUploadProgress from "./Reducers/fileUploadReducer";
import fileEdit from "./Reducers/fileEditSlice";
import manualMigration from "./Reducers/manualMigrationReducer";

const reducer = combineReducers({
    api,
    modal,
    aoMigration,
    migration,
    dropdown,
    forms,
    search,
    startGuide,
    smbStartGuide,
    bankList,
    users,
    von,
    internalMigration,
    alertReducer,
    productInformation,
    pagination,
    admin,
    fortnox,
    fileUploadProgress,
    fileEdit,
    manualMigration
});

export const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType,
    RootState,
    unknown,
    Action<string>>;
