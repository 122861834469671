import React, { useEffect } from "react";
import { useAdminActionCreator } from "./app/Actions/UseAdminActionCreator";
import { useAppSelector } from "./app/hooks";

export function ExceptionLogs() {

    const adminActions = useAdminActionCreator();
    const exceptions = useAppSelector(state => state.admin.exceptions);

    const [searchValue, setSearchValue] = React.useState("");

    const filteredExceptions = searchValue ? exceptions.filter((item) => item.id.includes(searchValue)) : exceptions;

    useEffect(() => {
        adminActions.getExceptions();
    }, [])

    if(!exceptions || exceptions.length === 0)
        return null;

    function search(e: React.ChangeEvent<HTMLInputElement>) {
        setSearchValue(e.target.value);
    }

    function sendToGdpr() {
        adminActions.sendToGdpr();
    }

    return <>
        <div className="panel">
            <div className="panel-body">

                <button onClick={sendToGdpr} className="btn btn-primary">Send to GDPR Clean (BETA)</button>

                <h2>Unhandled Exception logs</h2>

                <div className="form-group col-sm-10 col-md-6 ">
                    <input className="form-control" type="text" placeholder="Search for error Id" onChange={(e) => search(e)}/>
                </div>
                <div className="table table-responsive">
                <table className="table table-stripe">
                    <thead>
                    <tr>
                        <th>Error Id</th>
                        <th>Customer Id</th>
                        <th>Customer Number</th>
                        <th>Message</th>
                        <th>Stack Trace</th>
                        <th>User ID</th>
                        <th>Email</th>
                        <th>DateTime</th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredExceptions.map((item, index) => {
                        return <tr key={index}>
                            <td className="text-nowrap">{item.id}</td>
                            <td className="text-nowrap">{item.customerId}</td>
                            <td>{item.customerNumber}</td>
                            <td>{item.message}</td>
                            <td>{item.stackTrace}</td>
                            <td className="text-nowrap">{item.userId}</td>
                            <td className="text-nowrap">{item.email}</td>
                            <td className="text-nowrap">{item.date}</td>
                        </tr>

                    })}
                    </tbody>
                </table>
                </div>
            </div>
        </div>
    </>;
}