import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface FortnoxState {
  uri : string,
  migrations: Migration[]
}

export interface Migration {
  customerId: string;
  customerNumber: number;
  customerName: string;
  organizationNumber: string;
}


const initialState: FortnoxState = {
  uri : "",
  migrations: []
};

export const fortnoxSlice = createSlice({
  name: 'fortnox',
  initialState,
  reducers: {
    storeUri: (state, action: PayloadAction<string>) => {
      state.uri = action.payload;
    },
    storeCustomers: (state, action: PayloadAction<Migration[]>) => {
      state.migrations = action.payload
    }
  }
});
export const { storeUri, storeCustomers } = fortnoxSlice.actions;
export default fortnoxSlice.reducer;