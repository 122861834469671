import { useAppDispatch } from "../hooks";
import { useHttp } from "../../Utils/httpUtils";
import { ExceptionRow, ReportRow, storeExceptions, storeReportData } from "../Reducers/adminReducer";

export const useAdminActionCreator = () => {
    const dispatch = useAppDispatch();
    const {request} = useHttp();

    const getDoneMigrationsReport = () => {
        request(`/api/admin/getMigrationReportData`, null, (data: ReportRow[]) => dispatch(storeReportData(data)));
    };

    const getExceptions = () => {
        request(`/api/admin/getExceptions`, null, (data: ExceptionRow[]) => dispatch(storeExceptions(data)));
    };

    const sendToGdpr = () => {
        // This is not finished
        request(`/api/admin/SendToGdpr`, null, (data: any) => console.log(data));
    };

    return {
        getDoneMigrationsReport,
        getExceptions,
        sendToGdpr
    };
};