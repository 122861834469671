export const startGuideDescriptions = {
    productInformation: {
        header: "Samarbete",
        text: "För att kunna gå vidare med flytten till Visma eEkonomi måste du ha ett samarbete med din kund i Visma eEkonomi. Här nedan ser du de kunder som saknar ett samarbete. Välj den variant som passar bäst utifrån hur ert samarbete ska fungera och hur ni planerar att dela på arbetet. Våra populäraste varianter för ett samarbete är Visma eEkonomi Start, Visma eEkonomi Fakturering eller Visma eEkonomi Smart. Oavsett vilken variant du tilldelar din kund kommer du som byrå alltid arbeta i den största varianten som heter Visma eEkonomi Pro. Läs mer om de olika varianterna av Visma eEkonomi genom att klicka på info-ikonen."

    },
    invoiceInformation: {
        header: "Samarbete",
        text: "Här anger du vem som ska ta fakturan och även vilken avtalsperiod ni önskar. Har du vårt byråpaket Visma Smarta byrån så blir avtalsperioden automatiskt månadsvis."
    },
    accountingInformation: {
        header: "Bokföring",
        text: "I samband med att vi startar upp ett nytt Visma eEkonomi behöver vi ytterligare information för att kunna automatisera och förenkla arbetet framåt. Ange vilket bokföringskonto du önskar använda för företagskontot samt vilken bokföringsmetod som används."
    },
    bankInformation: {
        header: "Betalning",
        text: "Här får du ange vilken Bank och momsperiod som ska användas på kunden."
    },
    additionalInformation: {
        header: "Komplettering av information",
        text: "För att kunna gå vidare med flytten till Visma eEkonomi behöver du fylla på med information som vi inte kunde hitta i ditt företag."
    }
}