import { MigrationStatus } from "../Components/Enums/CommonEnums";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CompetitorMigration {
    id: string,
    competitor: Competitor
    status: MigrationStatus,
    customerName: string,
    organizationNumber: string,
}

export interface Competitor {
    id: string,
    name: string
}

interface ManualMigrationState {
    csvMigration: CompetitorMigration | null,
    competitors: Competitor[] | null
}

const initialState : ManualMigrationState = { csvMigration: null, competitors: null};

export const slice = createSlice({
    name: 'manualMigration',
    initialState,
    reducers: {
        storeCsvMigration: (state, action: PayloadAction<CompetitorMigration>) => {
            state.csvMigration = action.payload
        },
        storeCompetitors: (state, action:PayloadAction<Competitor[]>) => {
            state.competitors = action.payload;
        },
        setCompetitor: (state, action:PayloadAction<Competitor>) => {
            state.csvMigration!.competitor = action.payload;

        }
    },
});


export const {
    storeCsvMigration,
    storeCompetitors,
    setCompetitor
} = slice.actions;

export default slice.reducer;