import { useState } from "react";
import { useAppDispatch, useAppSelector } from "../hooks";
import { hideAlert } from "../Reducers/alertReducer";

export default function Alert(props: any) {
    const alert = useAppSelector(state => state.alertReducer.alert);
    const dispatch = useAppDispatch();    
    const [showingAlert, setShowAlert] = useState(false);
    
    let intervalId : any;

    function onCloseClick() {
        dispatch(hideAlert())
        clearInterval(intervalId)
        setShowAlert(false)
    }

    if (!alert){
        return null;
    }
    
    if(!showingAlert){
        intervalId = setInterval(onCloseClick,7000)
        setShowAlert(true)
    }

    return (
        <div className={`alert alert-${alert.type ? alert.type : "warning"} alert-sm alert-dismissible alert-window alert-${alert.style ? alert.style : "top"}`} role="alert">
            <button type="button" className="close" data-dismiss="alert" aria-label="Close" onClick={() => onCloseClick()}>Close</button>
            <strong>{alert.title ? alert.title : "Oh snap!"}</strong> <br/>{alert.message && 
            alert.message.split("\n").map((item : any, key : any) => {
                return <span key={key}>
                    {item}
                    <br />
                </span>
            })}
        </div>
    )
}