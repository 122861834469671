import { InternalMigration } from "../Reducers/internalMigrationReducer";
import { useHelpers } from "../../Utils/helpers";

function RenderNoData() {

    return <div className="alert alert-info"><strong>Ingen data</strong></div>
}

export function UserActionLog(props: { migration: InternalMigration }) {

    const helpers = useHelpers();
    var logs = props.migration.userActionLog;

    if(!logs)
        return null;

    if (logs.length === 0)
        return <RenderNoData />

    return <div>
        <div>
            <table className="table table-stripe">
                <thead>
                <tr>
                    <th>Användare</th>
                    <th>Meddelande</th>
                    <th>Skapad</th>
                </tr>
                </thead>
                <tbody>
                {logs.map((item, index: number) =>
                    <tr key={index}>
                        <td>{item.name}</td>
                        <td>{item.message}</td>
                        <td>{helpers.formatDateAndTime(item.created, true)}</td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    </div>;

}