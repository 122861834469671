import { useAppSelector } from "../../hooks";
import React from "react";
import { useFileActionCreator } from "../../Actions/FileActionCreator";
import LoadingSpinner from "../Icons/LoadingSpinner";

export function FileProgressPreview(props: { migrationId: string }) {

    const filesState = useAppSelector((state) => state.fileUploadProgress);
    const fileActions = useFileActionCreator();

    if (!filesState || filesState.filesDone.length === 0)
        return null;

    const deleteFile = (fileId: string | null) => {

        if (!fileId)
            return;

        fileActions.deleteFile(props.migrationId, fileId);
    }

    return <>
        <table className="table table-stripe">
            <thead>
            <tr>
                <th>Filnamn</th>
                <th></th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {filesState && filesState.filesDone.map((file, index) =>
                <tr key={index}>
                    <td>
                        <span className="font-weight-bold">{file.file.name}</span>
                    </td>
                    <td>
                        {file.validationResult && <a onClick={() => deleteFile(file.id)}><i className="vismaicon vismaicon-delete"></i></a>}
                    </td>
                    <td>
                        {!file.validationResult && <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}><LoadingSpinner width="24px" /> <p>Validerar innehållet</p></div>}
                        {file.validationResult && file.validationResult.success && <span className="vismaicon vismaicon-filled vismaicon-success"></span>}
                        {file.validationResult && !file.validationResult.success && <div style={{display: "flex", alignItems: "center", justifyContent: "space-between"}}><span className="vismaicon vismaicon-filled vismaicon-error"></span>  <p className="ml-4">{file.validationResult.message}</p></div>}
                    </td>
                </tr>)}
            </tbody>
        </table>

    </>;


}