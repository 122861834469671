import { useEffect } from "react";
import { useFortnoxActionCreator } from "../../Actions/FortnoxActionCreator";
import { useAppDispatch, useAppSelector } from "../../hooks";
import Rocket from "../Images/Pictogram-512-space-rocket.svg"
import { routes } from "../../Constants/routes";
import { useSearchParams } from "react-router-dom";
import { showAlert } from "../../Reducers/alertReducer";
import { SourceType } from "../Enums/CommonEnums";
export default function FortnoxSMBIntroPage() {

    const fortnoxActions = useFortnoxActionCreator();
    const fortnoxUri = useAppSelector(state => state.fortnox.uri);
    const [searchParams] = useSearchParams();
    const dispatch = useAppDispatch();

    useEffect(() => {

        const error = searchParams.get("Error");

        if(error){
            dispatch(showAlert({
                message: atob(error),
                type: "danger",
                title: "Fel vid anslutning till Fortnox",
                style: "top"
            }));
        }

        const redirectUri = window.location.origin + routes.startGuideSMB(SourceType.Fortnox.toString());
        fortnoxActions.getAuthUri(false, redirectUri);
    }, [])

    const openFortnoxLink = () => {
        window.location.href = fortnoxUri;
    }

    return (
        <div className="w100 d-flex justify-content-around">
            <section className="hero-card" style={{ width: "50%" }}>
                <div className="col-lg-6">
                    <div className="hero-card-content">
                        <h2 className="card-title">Välkommen till Visma Spcs Flytthjälp</h2>
                        <p>Här guidar vi dig steg för steg så att du på ett snabbt och enkelt sätt kan genomföra flytten till Visma eEkonomi med hjälp av vår integration till Fortnox. Du behöver ha köpt Visma eEkonomi och ha en integrationslicens hos Fortnox. Integration ingår i Fortnox Standard, och annars behöver du köpa en licens:
                            <a target="_blank" href="https://checkout.fortnox.se/checkout/your-purchase">Skaffa integrationslicens</a></p><br />
                        Klicka sen på knappen nedan för att hämta ditt företag från Fortnox.
                        <div className="btn-block">
                            <button type="button" className="btn btn-primary btn-lg" onClick={() => openFortnoxLink()}><span className="vismaicon vismaicon-add-circle"></span>Hämta företag</button>
                        </div>
                        <h3 className="bold">Vad följer med?</h3>
                        <p className="mb-32">Uppgifter som kommer att föras över till Visma eEkonomi är företagsuppgifter, kunder, leverantörer, artiklar, obetalda kundfakturor (kundreskontraposter),
                            obetalda leverantörsfakturor (leverantörsreskontraposter) och bokföringen för de sju senaste bokföringsåren. Har du kopplade bildunderlag till dina verifikationer följer dessa också med.</p>
                        <h3 className="bold">Status</h3>
                        <p >Under Status kan du följa ditt/dina företag och se när flytten är klar. När det är klart hittar du en logg där som visar hur flytten gått. Denna rekommenderar vi att du sparar och går igenom, för att se om det är något du behöver åtgärda i Visma eEkonomi innan du jobbar vidare där.</p>
                        <p>Skulle flytten ta stopp av någon anledning får vi en notifiering och kommer då att titta på ditt ärende. Vid behov kan vi även behöva kontakta dig för att slutföra flytten.</p>
                        <p className="mb-32">OBS! Det är viktigt att du inte jobbar vidare i ditt gamla program när du påbörjat flytten till Visma eEkonomi.</p>
                        {/* <div className="d-flex justify-content-around">
                            <div className="d-flex flex-wrap justify-content-between">
                                <div className="rounded-12 border shadow-0 p-12 px-32">
                                    <p className="mb-0 text-center font-family-ubuntu-semibold">Har du frågor eller kört fast? Kontakta Flytthjälpen på <span className="bold">010-458 44 80.</span></p>
                                    <p className="mb-0 text-center font-family-ubuntu-semibold">Se våra öppettider <a href="https://vismaspcs.se/kontakta-oss#support" target="_blank">här</a>.</p>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
                <div className="col-lg-6 justify-content-center justify-content-lg-end" style={{ flexWrap: "wrap", alignContent: "space-around" }}>
                    <picture className="hero-card-illustration hero-card-illustration-lg" style={{ height: "550px" }}>
                        <img src={Rocket} alt="House Illustration" />
                    </picture>
                </div>
            </section>
        </div>
    )
}