import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth, useAppSelector } from "../hooks";
import { hasRole } from "../../Utils/Users";
import { UserRole } from "../Reducers/userReducer";

export const RequireReadWriteAuth = () => {
    const { user } = useAuth();
    const currentUser = useAppSelector((state) => state.users.currentUser);

    const hasAccess = user && hasRole(currentUser, UserRole.ReadWrite)

    if (hasAccess)
        return <Outlet />;

    return <></>
}

export const RequireAdminAuth = () => {
    const { user } = useAuth();
    const currentUser = useAppSelector((state) => state.users.currentUser);

    const hasAccess = user && hasRole(currentUser, UserRole.Admin)

    if (hasAccess)
        return <Outlet />;

    return <></>
}

export const RequireAuth = () => {
    const { user } = useAuth();
    const location = useLocation();

    if (!user){
        localStorage.setItem("redirectTo", location.pathname + location.search);
    }

    return (
        user
            ? <Outlet />
            : <Navigate to="/login" state={{ from: location }} replace />
    );
}