
export const startGuideDropdowns = {
    product: [
        { id: 0, name: ""},
        { id: 1, name: "Visma eEkonomi Start"},
        { id: 2, name: "Visma eEkonomi Bokföring"},
        { id: 3, name: "Visma eEkonomi Fakturering"},
        { id: 4, name: "Visma eEkonomi Smart"},
        { id: 5, name: "Visma eEkonomi Pro"},
    ],
    smbProduct: [
        { id: 0, name: ""},
        { id: 2, name: "Visma eEkonomi Bokföring"},
        { id: 6, name: "Visma eEkonomi Fakturering"},
        { id: 4, name: "Visma eEkonomi Smart"},
        { id: 5, name: "Visma eEkonomi Pro"},
    ],
    invoiceReceiver: [
        { id: 1, name: "Kund"},
        { id: 2, name: "Byrå"}
    ],
    invoicePeriod: [
        { id: 1, name: "1 månad"},
        { id: 3, name: "3 månader"},
        { id: 12, name: "12 månader"}
    ],
    bookingAccount: [
        { id: 1, name: "1920" },
        { id: 2, name: "1930" },
    ],
    bookKeepingMethod: [
        { id: 1, name: "Faktureringsmetoden" },
        { id: 2, name: "Kontantmetoden" },
    ],
    vatPeriod: [
        { id: 1, name: "Redovisar ej moms" },
        { id: 2, name: "Kvartalsvis" },
        { id: 3, name: "Årsvis" },
        { id: 4, name: "En gång per månad (12:e i månaden)" },
        { id: 5, name: "En gång per månad (26:e i månaden)" },
    ],
    invoiceRecipient: [
        { id: 1, name: "Slutkund" },
        { id: 2, name: "Byrå" },
    ],
    bank: [
        { id: 1, name: "Handelsbanken" },
        { id: 2, name: "Swedbank" },
        { id: 3, name: "SEB" },
    ]
}

export const userRoles = [
    { id: 0, name: "ReadOnly" },
    { id: 1, name: "ReadWrite" },
    { id: 2, name: "Admin" }
]