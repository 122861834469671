import './App.css';
import Login from "./app/Components/Login";
import Loader from "./app/Components/Loader";
import TopNavigation from "./app/Components/TopNavigation";
import { BrowserRouter as Router, Outlet, Route, Routes } from 'react-router-dom';
import { routes } from "./app/Constants/routes";
import AODetailedStatusList from "./app/Components/AODetailedStatusList";
import StartGuide from "./app/Components/StartGuide";
import InternalList from "./app/Components/InternalList";
import InternalMigrationDetails from "./app/Components/InternalMigrationDetails";
import Footer from "./app/Components/Footer";
import Alert from './app/Components/Alert';
import StartPage from "./app/Components/StartPage";
import AOSummarizedListContainer from "./app/Components/AOSummarizedListContainer";
import ScrollButton from "./app/Components/Helpers/ScrollToTopButton";
import { RequireAdminAuth, RequireAuth, RequireReadWriteAuth } from "./app/Components/RequireAuth";
import CreateTestMigration from "./app/Components/CreateTestMigration";
import { AdminReportPage } from "./app/Components/AdminReportPage";
import { DownloadProgress } from "./app/Components/DownloadProgress";
import SMBStartGuide from './app/Components/SMB/SMBStartGuide';
import AdditionalInformationContainer from "./app/Components/AdditionalInformationContainer";
import SMBDetailedStatus from './app/Components/SMB/SMBDetailedStatus';
import FortnoxSMBIntroPage from './app/Components/Fortnox/FortnoxSMBIntroPage';
import FortnoxAOIntroPage from './app/Components/Fortnox/FortnoxAOIntroPage';
import CSVImportPage from './app/Components/CSV/CSVImportPage';
import CSVIntroPage from './app/Components/CSV/CSVIntroPage';
import React from "react";
import { ExceptionLogs } from "./ExceptionLogs";
import { ImportFilesFinishConfirmation } from './app/Components/ImportFilesFinishConfirmation';


function App() {
    const ContainerLayout = () => {
        return <div className="container"><Outlet /></div>;
    };

    const FluidLayout = () => {
        return <div className="container-fluid"><Outlet /></div>;
    };

    return (
        <>
        <DownloadProgress />
        <Router>
            <TopNavigation />
            <Alert />
            <main>
                <Routes>
                    <Route path={routes.login} element={<Login />} />
                    <Route element={<RequireAuth />}>
                        <Route path={"/"} element={<ContainerLayout />}>
                            <Route path={"/"} element={<StartPage />} />
                            <Route path={routes.csvImport} element={<CSVImportPage />} />
                            <Route path={routes.csvIntro} element={<CSVIntroPage/>} />
                            <Route path={routes.summarizedList} element={<AOSummarizedListContainer />} />
                            <Route path={routes.aoStatusList(":id", ":status")} element={<AODetailedStatusList />} />
                            <Route path={routes.startGuide()} element={<StartGuide />} />
                            <Route path={routes.additionalInfo(':id')} element={<AdditionalInformationContainer />} />
                                <Route path={routes.smbMigrationDetails(':sourceType')} element={<SMBDetailedStatus />} />
                                <Route path={routes.importFilesConfirm} element={<ImportFilesFinishConfirmation/>} />
                        </Route>
                        <Route path={"/"} element={<FluidLayout />}>
                            <Route path={routes.fortnoxSMBIntro} element={<FortnoxSMBIntroPage/>}/>
                            <Route path={routes.fortnoxAOIntro} element={<FortnoxAOIntroPage/>}/>
                            <Route path={routes.startGuideSMB(':sourceType')} element={<SMBStartGuide />} />
                        </Route>
                        <Route element={<RequireReadWriteAuth />}>
                            <Route path={"/"} element={<FluidLayout />}>
                                <Route path={routes.internalList} element={<InternalList />} />
                                <Route path={routes.internalMigrationDetails(':id')}
                                    element={<InternalMigrationDetails />} />
                            </Route>
                        </Route>
                        <Route element={<RequireAdminAuth />}>
                            <Route path={"/"} element={<FluidLayout />}>
                                <Route path={routes.adminPageCreateTestMigration} element={<CreateTestMigration />} />
                                <Route path={routes.adminReportPage} element={<AdminReportPage />} />
                                <Route path={routes.exceptionLogs} element={<ExceptionLogs/>} />
                            </Route>
                        </Route>
                    </Route>
                </Routes>
                <Loader></Loader>
                <ScrollButton />
            </main>
            <Footer />
        </Router>
        </>
    );
}

export default App;
