import React from 'react';

type WootricProps = {
    email: string;
    timestamp: number;
};
class Wootric extends React.Component<WootricProps> {
    componentDidMount() {
        const { email, timestamp } = this.props;

        let setupScript = document.createElement('script');
        setupScript.type = 'text/javascript';
        setupScript.id = 'wootric-settings';
        setupScript.async = true;
        setupScript.innerHTML = `
            window.wootricSettings = {
                email: '${email}',
                account_token: 'NPS-EU-1ccbf2ac',
                created_at: '${timestamp}'
            };
        `;
        if (document.getElementById('wootric-settings') == null) {
            document.body.appendChild(setupScript);
        }

        // Beacon
        let beacon = document.createElement('script');
        beacon.type = 'text/javascript';
        beacon.id = 'wootric-beacon';
        beacon.async = true;
        beacon.src = 'https://cdn.wootric.com/wootric-sdk.js';
        beacon.onload = function () {
            window.wootric('run');
        };
        if (document.getElementById('wootric-beacon') == null) {
            document.body.appendChild(beacon);
        }
    }

    render() {
        return (
            <div />
        );
    }
}

export default Wootric;
