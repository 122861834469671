import './CSS/Checkbox.css'

export default function Checkbox(props: any) {
    return (
        <div className={`form-group full-width ${props.customClass}`}>
            <div className="checkbox">
                <input key={props.value} onChange={(e) => props.onChange(e.target.checked, props.field)} type="checkbox" id={props.id ? props.id : "checkbox-example"} checked={props.value} />
                <label htmlFor={props.id ? props.id : "checkbox-example"}>{props.label}</label>
            </div>
        </div>
    )
}

export function CheckboxPure(props: any) {

    return <div className={`checkbox`} style={{margin: 0}} >
                <input key={props.value} onChange={(e) => props.onChange(e.target.checked, props.field)} type="checkbox" id={props.id ? props.id : "checkbox-example"} checked={props.value} />
                <label htmlFor={props.id ? props.id : "checkbox-example"}>{props.label}</label>
            </div>
}