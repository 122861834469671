import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { StartGuideData } from '../Actions/MigrationActionCreator';
import { Bank } from './startguideReducer';

const requiredCustomerInfoFields: Array<keyof StartGuideObject['customerInfo']> = [
  // 'phoneNumber',
  'bookingAccount',
  'bookKeepingMethod',
  'vatPeriod',
  'bank',
];

export interface StartGuideObject {
  customerInfo: {
    // phoneNumber: string;
    bookingAccount: number;
    bookKeepingMethod: number;
    vatPeriod: string;
    bank: Bank;
  };
  isCustomerInfoComplete: boolean,
  product: Product
}

export interface SMBStartGuideState {
  startGuide: StartGuideObject;
  loading: boolean;
}

export interface Product {
  id: number,
  period: number,
  price: number
}

const initialState: SMBStartGuideState = {
  startGuide: {
    customerInfo: {
      // phoneNumber: "",
      bookingAccount: 0,
      bookKeepingMethod: 0,
      vatPeriod: "",
      bank: {
        id: "",
        name: ""
      },
    },
    product: {
      id: 0,
      period: 0,
      price: 0
    },
    isCustomerInfoComplete: false
  },
  loading: false
};

interface UpdateStartGuide {
  field: string;
  value: any;
}

function setProperty<T>(obj: Record<string, T>, key: string, value: T) {
  obj[key] = value;
}

export const smbStartGuideSlice = createSlice({
  name: 'smbStartGuide',
  initialState,
  reducers: {
    fetchSMBStartGuideData: (state) => {
      state.loading = true;
    },
    setSMBCustomerInfoValue: (state, action: PayloadAction<UpdateStartGuide>) => {
      const { field, value } = action.payload;
      setProperty(state.startGuide.customerInfo, field, value);
      state.startGuide.customerInfo = { ...state.startGuide.customerInfo };

      state.startGuide.isCustomerInfoComplete = requiredCustomerInfoFields.every(
        (field) => !!state.startGuide.customerInfo[field]
      );
    },
    setSMBStartGuideValue: (state, action: PayloadAction<UpdateStartGuide>) => {
      setProperty(state.startGuide, action.payload.field, action.payload.value);
    }
  },
});

export const { fetchSMBStartGuideData, setSMBCustomerInfoValue, setSMBStartGuideValue } = smbStartGuideSlice.actions;
export default smbStartGuideSlice.reducer;