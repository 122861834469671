const productionUri = "flytt.hjalp.vismaspcs.se";

export const isProduction = window.location.host === productionUri;

const env = isProduction ? "prod" : "test";

export const urls = {
    cockpit: {
        prod: "https://skynet.vismaspcs.se",
        test: "https://skynet.stage.vismaspcs.se",
    },
    internalCustomerAdmin: {
        prod: "https://admin.vismaonline.com/Internal",
        test: "https://admin.stage.vismaonline.com/Internal",
    },
    vonMyServices: {
      prod: "https://www.vismaonline.com/home",
      test: "https://myservices.stage.vismaonline.com/",
    },
    vonConnectIdServer: {
        prod: "https://identity.vismaonline.com",
        test: "https://identity.stage.vismaonline.com"
    },
    adminTool: {
        prod: "https://eaccountingadmintool.vismaonline.com",
        test: "https://eaccountingadmintool.stage.vismaonline.com"
    }
}

export const useUrlHelper = () => {

    const openInNewTab = (url: string) => {
        let a= document.createElement('a');
        a.target= '_blank';
        a.href= url;
        a.click();
    }

    const startService = (customerId : string) => {
        let url = isProduction ? urls.vonConnectIdServer.prod : urls.vonConnectIdServer.test;
        url += "/signin/redirect";

        const params = new URLSearchParams();

        params.append("sitename", "tegner");
        params.append("customerId", customerId);
        params.append("applicationId", "44643eb1-3f76-4c1c-a672-402ae8085934");

        url += "?" + params.toString();
        openInNewTab(url);
    }

    const openInternalCustomeradmin = (customerNumber: string) : void => {

        let url = urls.internalCustomerAdmin[env];

        if(!customerNumber)
            url += `/Customers.aspx`;
        else
            url += `/customerinformationtabstrip.aspx?customerno=${customerNumber}`;

        openInNewTab(url)
    }

    const openCockpit = (customerNumber: string) : void =>
    {
        let url = urls.cockpit[env];

        if(customerNumber)
            url+= `/customer/${customerNumber}/main`;

        openInNewTab(url)
    }

    const getVismaOnlineStartPage = () : string => urls.vonMyServices[env];
    const getIdentityServerUrl = () : string => urls.vonConnectIdServer[env];
    const startMyServices = () => openInNewTab(urls.vonMyServices[env])

    return {
        startService,
        startMyServices,
        getVismaOnlineStartPage,
        getIdentityServerUrl,
        openInternalCustomeradmin,
        openCockpit
    }
}