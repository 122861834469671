import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IFileValidationResult } from "../Actions/FileActionCreator";

interface FileProgress {
    file: File,
    id: string | null,
    percentDone: number
    isDone: boolean
    validationResult?: IFileValidationResult
}

interface FilesState {
    progress: FileProgress[],
    filesDone: FileProgress[]
}

const initialState: FilesState = {
    progress: [],
    filesDone: []
};

export const fileUploadSlice = createSlice({
    name: 'fileUpload',
    initialState,
    reducers: {
        addFile: (state, action: PayloadAction<File>) => {
            state.progress.push({file: action.payload, percentDone: 0, isDone: false, id: null});
        },
        removeFile: (state, action: PayloadAction<File>) => {
            state.progress = state.progress.filter((file) => file.file.name !== action.payload.name);
        },
        updateProgress: (state, action: PayloadAction<{ file: File, progress: number }>) => {
            const file = state.progress.find((progress) => progress.file.name === action.payload.file.name);

            if (file)
                file.percentDone = Math.round(action.payload.progress);
        },
        doneFile: (state, action: PayloadAction<File>) => {

            const file = state.progress.find((progress) => progress.file.name === action.payload.name);

            if (file) {
                file.isDone = true;
                state.filesDone.push(file);
                state.progress = state.progress.filter((progress) => progress.file.name !== action.payload.name);
            }
        },
        setFileId: (state, action: PayloadAction<{ file: File, id: string }>) => {
            const file = state.progress.find((progress) => progress.file.name === action.payload.file.name);

            if (file)
                file.id = action.payload.id;
        },
        setValidated: (state, action: PayloadAction<{ fileId: string, validationResult: IFileValidationResult}>) => {

            const file = state.filesDone.find((progress) => progress.id === action.payload.fileId);

            if (file)
                file.validationResult = action.payload.validationResult;
        },
        deleteUploadedFile: (state, action: PayloadAction<string>) => {
            state.filesDone = state.filesDone.filter((file) => file.id !== action.payload);
        }
    }
});

export const {addFile, removeFile, updateProgress, doneFile, setFileId, deleteUploadedFile, setValidated} = fileUploadSlice.actions;
export default fileUploadSlice.reducer;