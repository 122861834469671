import { useInternalActionCreator } from "../Actions/InternalActionCreator";
import TextInput from "./Helpers/TextInput";
import { useEffect, useState } from "react";
import { useHelpers } from "../../Utils/helpers";
import { MigrationComment } from "../Reducers/internalMigrationReducer";
import { useAppSelector } from "../hooks";

export default function MigrationComments(props:{ migrationId: string}) {
    const migrationActions = useInternalActionCreator();
    const [newComment, setNewComment] = useState("");
    const helpers = useHelpers();
    const comments = useAppSelector((state) => state.internalMigration.internalMigration.comments);

    useEffect(() => {
        migrationActions.getCommentsForMigration(props.migrationId);
    }, []);

    function addNewComment() {
        setNewComment("");
        migrationActions.addCommentForMigration(props.migrationId, newComment)
    }

    function linkify(inputText: string): string {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return inputText.replace(urlRegex, url => `<a href="${url}" target="_blank">${url}</a>`);
    }

    return <>
          <div>
              { comments && comments.map((comment:MigrationComment, index:number) => {
                  return <div className="bg-primary comment-container">
                      <div className="comment-header">
                          <span className="font-weight-bold">{comment.addedByName}</span>
                          <span className="font-weight-light">{helpers.formatDateAndTime(comment.dateStamp, true)}</span>
                      </div>
                      <div className="comment">
                          <div dangerouslySetInnerHTML={{ __html: linkify(comment.comment)}} />
                      </div>
                  </div>

              })}
              {comments && comments.length === 0 && <div className="comment-container">Inga kommentarer än..</div>}

          </div>
          <div>
              <TextInput elementWidth={12} multiline={true} onChange={(value:string) => setNewComment(value)} field={"comment"} value={newComment} placeholder="Kommentar..." />
              <br/>
              <button className="btn btn-primary float-right" onClick={addNewComment}>Lägg till kommentar</button>
          </div>
          <br/>
      </>
}