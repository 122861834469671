import { useAppDispatch, useAppSelector } from "../hooks";
import { useFileActionCreator } from "../Actions/FileActionCreator";
import { addRow, removeRow, updateContent, updateJsonData } from "../Reducers/fileEditSlice";
import { jsonToCsv } from "./CSV/CsvHelper";
import Modal from "./Helpers/Modal";
import CodeMirror from "@uiw/react-codemirror";
import React from "react";

export function EditFileModal() {

    const fileName = useAppSelector((state) => state.fileEdit.fileName);
    const code = useAppSelector((state) => state.fileEdit.fileContent);
    const migration = useAppSelector((state) => state.internalMigration.internalMigration);
    var jsonData = useAppSelector((state) => state.fileEdit.jsonData);

    const dispatch = useAppDispatch();
    const fileActions = useFileActionCreator();

    const setCode = (code: string) => {
        dispatch(updateContent(code));
    };

    const save = (content: string) => {
        const blob = new Blob([content], {type: "text/plain"});
        var file: File = new File([blob], fileName);
        fileActions.uploadFileSigned(migration.id, file);
    };

    const saveCode = () => save(code);

    const saveCsvData = () => {
        save(jsonToCsv(jsonData));
    };

    const darkMode = localStorage.getItem("dark-mode");
    const theme = !!darkMode && darkMode === 'dark-theme' ? "dark" : "light";
    const isCsv = fileName.endsWith(".csv");

    return <Modal title={`Redigera ${fileName}`} hideButtons={false} size="modal-xl"
                  onSave={isCsv ? saveCsvData : saveCode}>
        {isCsv && <CsvFileEditor/>}
        {!isCsv && <CodeMirror
            theme={theme}
            value={code}
            onChange={(code) => setCode(code)}
            style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
            }}
        />}
    </Modal>;
}

function CsvFileEditor() {

    const jsonData = useAppSelector((state) => state.fileEdit.jsonData);
    const headers = jsonData.length > 0 ? Object.keys(jsonData[0]) : [];
    const dispatch = useAppDispatch();

    const onChange = (event: React.ChangeEvent<HTMLInputElement>, header: string, index: number) => {
        dispatch(updateJsonData({header, index, value: event.target.value}));
    }

    return <table className="table table-stripe">
        <thead>
        <tr>
            <th></th>
            <th>Radnr</th>
            {headers.map((header, index) => {
                return <th key={index}>{header}</th>
            })}
        </tr>
        </thead>
        <tbody>
        {
            jsonData.map((row, rowIndex) => {
                return <tr key={rowIndex}>
                    <td className="text-nowrap">
                        <span aria-hidden="true" className="vismaicon vismaicon-remove-circle cursorpointer" onClick={() => dispatch(removeRow(rowIndex))}></span>
                        {rowIndex == jsonData.length - 1 && <span aria-hidden="true" className="vismaicon vismaicon-add-circle cursorpointer" onClick={() => dispatch(addRow())}></span>}
                    </td>
                    <td>{rowIndex + 1}</td>
                    {headers.map((header, index) => {
                        return <td key={index} className="text-nowrap"><input type="text" value={row[header]} onChange={(e) => onChange(e, header, rowIndex)}/></td>
                    })}
                </tr>
            })
        }
        </tbody>
    </table>
}