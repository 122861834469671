import React, { useEffect, useState } from 'react';
import DragAndDrop from '../Helpers/DragAndDrop';
import DropdownButton from '../Helpers/DropdownButton';
import { useMigrationActionCreator } from "../../Actions/MigrationActionCreator";
import { MigrationStatus, SourceType } from "../Enums/CommonEnums";
import { useAppDispatch, useAppSelector } from "../../hooks";
import {
    Competitor,
    CompetitorMigration,
    setCompetitor
} from "../../Reducers/manualMigrationReducer";
import { modalTypes } from "../../Constants/modalTypes";
import { onToggleModal } from "../../Reducers/modalReducer";
import { ModalAlert } from "../Helpers/Modal";

type CsvCustmerConfirmationProps = {
    migration: CompetitorMigration;
}

function CustomerConfirmationModal({migration} : CsvCustmerConfirmationProps) {
    const migrationAction = useMigrationActionCreator();

    return <ModalAlert title="Bekräfta överföring av filer" mainButtonDisabled={false} mainButtonTitle={"Slutför"} onSave={() => migrationAction.startManualMigration(migration)}>
        <p>Filerna kommer att skickas till företaget <strong>{migration.customerName}</strong> med organisationsnummer <strong>{migration.organizationNumber}</strong></p>
    </ModalAlert>;
}

export default function CSVImportPage() {
    const migrationAction = useMigrationActionCreator();
    const migration = useAppSelector(state => state.manualMigration.csvMigration);
    const competitors = useAppSelector(state => state.manualMigration.competitors);

    const activeModal = useAppSelector((state) => state.modal.modal);

    const dispatch = useAppDispatch();

    useEffect(() => {
        migrationAction.getOrCreateManualMigration(SourceType.CsvFiles)
        migrationAction.getCompetitors();
    }, []);

    if(!migration || !competitors)
        return null;

    const onSelect = (item: Competitor) => {
        dispatch(setCompetitor(item));
    };

    const sendMigration = () => {
        dispatch(onToggleModal(modalTypes.customerConfirmationSendFiles));
    }

    return <div className="d-flex justify-content-around">
        <div className='hero-card col-10'>
            <div className='py-48 px-32 w-100'>
                <div className="row align-items-center justify-content-around mb-32">
                    <div className='col-7'>
                        <div style={{ maxWidth: "550px" }}>
                            <h1>Välkommen till Visma Spcs Flytthjälp</h1>
                            <p>Här väljer du vilket program eller leverantör du byter från och laddar sedan enkelt upp dina registerfiler och så fixar vi resten!</p>

                            <h2>Vilka filer kan jag ladda upp?</h2>
                            <p>Det är kund-, artikel- och leverantörsregister vi kan flytta in till Visma eEkonomi och de vanligaste filformaten är .csv och .txt.</p>

                            <p>Du hittar mer information och instruktioner kring hur du exporterar ut rätt filer från just ditt program på sidan innan, här: <a href="https://vismaspcs.se/support/overforing-till-visma-eekonomi" target="_blank" >Flytta till Visma eEkonomi »</a></p>

                            <h2>När är det klart?</h2>
                            <p>Vi försöker alltid flytta in dina register inom 48 tim på vardagar och när det är klart skickar vi ett mejl till den e-postadress som du loggade in med här.</p>

                            <p>Om du har frågor kring din flytt är du välkommen att kontakta oss på <a href="mailto:dataflytt.spcs@visma.com">dataflytt.spcs@visma.com</a>.</p>
                        </div>
                    </div>
                    <div className='col p-0'>
                        <div className="panel rounded-8 p-16 shadow-40">
                            <div className='p-32 rounded-8 bg-hero-card'>
                                <div className='d-flex flex-column align-items-center'>
                                    <div className="mb-64">
                                        <DropdownButton list={competitors} onSelect={onSelect}>
                                            <button type="button" className="btn btn-lg w-100 dropdown-toggle">{migration.competitor ? migration.competitor.name : "Välj program/leverantör"}</button>
                                        </DropdownButton>
                                    </div>
                                    <div className='w-100'>
                                        <DragAndDrop disabled={!migration || !migration.competitor} migrationId={migration.id} >
                                            <p>Dra hit filer eller klicka för att ladda upp</p>
                                        </DragAndDrop>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {migration.status > MigrationStatus.New && <div className="alert alert-info" role="alert">
                Du har redan skickat filerna en gång. Skicka bara in nya filer i samråd med supporten.
                </div>}
                <div className='row justify-content-end'>
                    <div className='d-flex '>
                        <button type="button" className="btn btn-primary btn-lg" onClick={sendMigration}>Skicka för överflytt</button>
                    </div>
                </div>
            </div>
        </div>
        {activeModal === modalTypes.customerConfirmationSendFiles && <CustomerConfirmationModal migration={migration}/>}
    </div>
}

