import {createSlice, PayloadAction} from '@reduxjs/toolkit';

export interface Field {
    [inputId: string]: any
}
const initialState: Field = {
    inputs: { value: ""}
};

export const dropdownSlice = createSlice({
   name: 'dropdown',
    initialState,
   reducers: {
       setValue: (state, action: PayloadAction<{id: string, value: any}>) => {
           state.inputs[action.payload.id] = action.payload.value
       }
   }
});

export const {setValue} = dropdownSlice.actions;
export default dropdownSlice.reducer;