import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AOState {
    migrationCount: any
}

const initialState : AOState = {migrationCount: {}};

export const aoSlice = createSlice({
    name: 'aoMigration',
    initialState,
    reducers: {
        storeMigrationCount: (state, action:PayloadAction<any>) => {
            state.migrationCount = action.payload;
        }
    },
});

export const {storeMigrationCount} = aoSlice.actions;
export default aoSlice.reducer;
