import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { csvToJson } from "../Components/CSV/CsvHelper";

interface IFileEdit {
    fileContent: string,
    fileName: string,
    jsonData: any[],
}

const initialState: IFileEdit = {
    fileContent: "",
    fileName: "",
    jsonData: []
}

export const fileEditSlice = createSlice({
    name: 'fileEdit',
    initialState,
    reducers: {
        initFile: (state, action: PayloadAction<{content: string, fileName: string}>) => {
            state.fileContent = action.payload.content;
            state.fileName = action.payload.fileName;

            if (action.payload.fileName.endsWith(".csv")) {
                state.jsonData = csvToJson(action.payload.content);
            }
            else {
                state.jsonData = [];
            }
        },
        updateContent: (state, action: PayloadAction<string>) => {
            state.fileContent = action.payload;
        },
        updateJsonData: (state, action: PayloadAction<{header: string, index: number, value: string}>) => {
            state.jsonData[action.payload.index][action.payload.header] = action.payload.value;
        },
        addRow: (state) => {
            state.jsonData.push({});
        },
        removeRow: (state, action: PayloadAction<number>) => {
            state.jsonData.splice(action.payload, 1);
        },
        clear: (state) => {
            state.fileContent = "";
            state.fileName = "";
            state.jsonData = [];
        }
    }
});


export const {
    initFile,
    updateContent,
    updateJsonData,
    addRow,
    removeRow,
    clear
} = fileEditSlice.actions;
export default fileEditSlice.reducer;