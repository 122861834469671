import React, { useState } from "react";
import RightArrowIcon from "./Icons/RightArrowIcon";
import LoadingIcon from "./Icons/LoadingIcon";
import { useNavigate } from "react-router-dom";
import { routes } from "../Constants/routes";
import Checkbox from "./Helpers/Checkbox";
import { setSelectedClients } from "../Reducers/startguideReducer";
import { useAppDispatch } from "../hooks";

export default function AOSummarizedMigrationStatusList(props: any) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [expanded, setExpanded] = useState(false)
    const [selectedMigrations, setSelectedMigrations] = useState<string[]>([]);

    const toggleSelection = (migrationId: string) => {
        if (selectedMigrations.includes(migrationId)) {
            setSelectedMigrations(selectedMigrations.filter(id => id !== migrationId));
        } else {
            setSelectedMigrations([...selectedMigrations, migrationId]);
        }
    };

    const selectAllMigrations = () => {
        if (selectedMigrations.length === props.migrations.length) {
            setSelectedMigrations([]);
        } else {
            const allMigrationIds = props.migrations.map((migration: any) => migration.id);
            setSelectedMigrations(allMigrationIds);
        }
    };

    return (
        <div onClick={() => setExpanded(!expanded)} className={`mb-8 rounded bg-primary shadow-30 container-padding cursor-pointer migration-status-list-container cursorpointer ${expanded ? 'expanded-ao-list' : 'hidden-ao-list'}`}>
            <div>
                <div className="pt-0 pl-0">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex">
                            <i className={"pr-4 vismaicon vismaicon-dynamic vismaicon-edit"}></i>
                            <p className="pl-4 pt-4 font-family-ubuntu-semibold">
                                {props.migrations.length} nya att hantera
                            </p>
                        </div>
                        <RightArrowIcon />
                    </div>
                </div>
                <div onClick={(e) => e.stopPropagation()} style={{ cursor: "default" }} className={`ao-list-content`}>
                    <div className="panel-body pb-12">
                        <div>
                            <button
                                type="button"
                                className="btn float-left mb-32"
                                onClick={selectAllMigrations}
                            >
                                {selectedMigrations.length === props.migrations.length ? "Avmarkera alla" : "Välj alla"}
                            </button>
                        </div>
                        {props.migrations.map((migration: any) => (
                            <Checkbox
                                key={migration.id}
                                label={migration.customerName + " - " + migration.organizationNumber}
                                id={migration.id}
                                value={selectedMigrations.includes(migration.id)}
                                onChange={() => toggleSelection(migration.id)}
                                customClass="mb-12"
                            />
                        ))}
                    </div>
                    <div className="full-width d-inline-block">
                        <div>
                            <button
                                type="button"
                                className="btn btn-primary float-right"
                                onClick={() => {
                                    dispatch(setSelectedClients(selectedMigrations))
                                    navigate(`${routes.startGuide()}`);
                                }}
                                disabled={selectedMigrations.length === 0}
                            >
                                Nästa
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
