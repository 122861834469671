import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserModel {
    id: string;
    connectUserId: string;
    fullName: string;
    firstName: string;
    surname: string;
    email: string;
    isAdministrator: boolean;
    isEaccountingUser: boolean;
    isConsultant: boolean;
    isActive: boolean
}

export interface VonUsers {
    vonUsers: UserModel[]
}

const initialState: VonUsers = {vonUsers: []};

export const vonSlice = createSlice({
    name: "von",
    initialState,
    reducers: {
        storeVonUsers: (state, action:PayloadAction<UserModel[]>) => {
            state.vonUsers = action.payload;
        }
    }
})

export const {storeVonUsers} = vonSlice.actions;
export default vonSlice.reducer;