import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ProductInformation {
    id: string,
    productName: string,
    productDescription: string,
    website : string,
    productPrice : number
}

export interface ProductInformationList {
    productInformationList: Array<ProductInformation> | null
}

const initialState : ProductInformationList = {productInformationList : null};

export const prooductInformationListSlice = createSlice({
    name: 'productInformationList',
    initialState,
    reducers: {
        storeProductInformationList: (state, action:PayloadAction<any>) => {
            state.productInformationList = action.payload;
        }
    },
});

export const { storeProductInformationList } = prooductInformationListSlice.actions;
export default prooductInformationListSlice.reducer;
