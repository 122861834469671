import { useEffect } from "react";
import { useMigrationActionCreator } from "../Actions/MigrationActionCreator";
import { useAppSelector } from "../hooks";
import { useAdminActionCreator } from "../Actions/UseAdminActionCreator";

export function AdminReportPage() {

    const adminActions = useAdminActionCreator();
    const reportData = useAppSelector(state => state.admin.reportData);

    useEffect(() => {
        adminActions.getDoneMigrationsReport();
    }, [])

    if(!reportData)
        return null;

    return <>
        <div className="panel">
            <div className="panel-body">
                <h2>Rapport över överflyttade företag</h2>
                <p>Rapporten visar företag som har flyttats över till Visma eEkonomi grupperat på period. Samtliga migreringar måste vara slutförda och ha status <strong>Done</strong> eller <strong>Completed</strong></p>
                <table className="table table-stripe">
                    <thead>
                    <tr>
                        <th>År/Månad</th>
                        <th>Antal flytter med handpåläggning</th>
                        <th>Totalt antal flytter</th>
                        <th>Procent automatiskt</th>
                    </tr>
                    </thead>
                    <tbody>
                    {reportData.map((item, index) => {
                        return <tr key={index}>
                                <td>{item.formatedyearMonth}</td>
                                <td>{item.manualCount}</td>
                                <td>{item.migrationsCount}</td>
                                <td>{item.percentAutomatic * 100}%</td>
                            </tr>

                    })}
                    </tbody>
                </table>

            </div>
        </div>
    </>;


}