import { getAutorizationLink } from "../VismaConnect";
import { useAuth } from "../hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function Login() {

    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code')?.toString(); // hämtar code från URL

    const { login } = useAuth();
    const navigate = useNavigate();

    const onLoginSuccess = () => {
        const redirectTo: string = localStorage.getItem("redirectTo") || "/"
        navigate(redirectTo, {replace: true})
    }

    const onClick = async () => {
        var authLink = await getAutorizationLink();
        window.location.href = authLink;
    }

    useEffect(() => {
        login(code || "", onLoginSuccess)
    }, [])

    return (
            <div className="text-center">
                <h1>Välkommen</h1>
                <h2><a className="btn btn-primary btn-lg" style={{textDecoration: 'none', fontSize: 14}} onClick={onClick}>Logga in</a></h2>
            </div>
    );

}