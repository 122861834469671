import { useCallback, useRef } from "react";
import { useAppDispatch, useAppSelector } from "../app/hooks";
import { downloadProgress, isDownloading, Progress, setIsLoading } from "../app/Reducers/apiReducer";
import { useAuth } from "../app/hooks";
import { showAlert } from "../app/Reducers/alertReducer"
import { contentType } from "../app/Components/Enums/contentType";
import { StatusCodes } from "http-status-codes";

let ApiBaseUri: string = ""

export const useHttp = () => {
    const dispatch = useAppDispatch();
    const controllerRef = useRef<AbortController | null>();

    const loadingState = useAppSelector((state) => state.api.isLoading);
    const errorMessage = useAppSelector((state) => state.api.error);
    const { user, refresh } = useAuth();

    const HandleDownloading = useCallback(async (response: Response, attachmentName?: string) => {

        if(!response.body)
            return null;

        const reader = response.body.getReader();

        dispatch(isDownloading(true));

        // @ts-ignore
        const contentLength = + response.headers.get('Content-Length') ;

        let receivedLength = 0;
        let chunks = [];
        while(true) {
            const { done, value } = await reader.read();

            if (done)
                break;

            chunks.push(value);
            receivedLength += value.length;

            const progress = new Progress(contentLength, receivedLength, attachmentName as string);

            dispatch(downloadProgress(progress));
        }

        dispatch(isDownloading(false));
        return new Blob(chunks);
    },[dispatch])

    const getReqObj = useCallback((requestObj: RequestInit | null, useAuthorization: boolean): RequestInit => {

        if (!requestObj) {
            requestObj = { method: "GET", signal: controllerRef.current?.signal };
        }

        // When using aws signed url we cannot use our header value for authorization
        if (user?.accessToken && useAuthorization) {
            requestObj.headers = { ...requestObj.headers, Authorization: "Bearer " + user.accessToken, 'Content-Type': 'application/json'};
        }

        return requestObj;
    }, [user?.accessToken])

    const request = useCallback(
        async (url: string, requestObj: RequestInit | null, callback?: Function, useAuthorization: boolean = true, attachmentName?: string) => {
            if(controllerRef.current) {
                controllerRef.current.abort();
            }
            controllerRef.current = new AbortController();
            
            requestObj = getReqObj(requestObj, useAuthorization);
            
            dispatch(setIsLoading(true));

            let requestUri = ApiBaseUri + url;

            try {

                if (url.startsWith("http"))
                    requestUri = url;

                let response = await fetch(requestUri, requestObj);

                if (response.status === StatusCodes.UNAUTHORIZED && user) {
                    await refresh(user);
                    response = await fetch(requestUri, getReqObj(requestObj, useAuthorization));
                }

                let data;
                if (response.headers.get('Content-Type') === contentType.textPlain) {
                    data = await response.text();
                } else if (response.headers.get('Content-Type') === contentType.octetStream) {
                    data = await HandleDownloading(response, attachmentName)
                } else {
                    const string = await response.text();
                    data = string === "" ? {} : JSON.parse(string);
                    controllerRef.current = null;
                }

                if (data.Error)
                    throw data

                if (callback)
                    callback(data);
            } catch (error: any) {
                if (error.name !== "AbortError")
                    dispatch(showAlert({ message: error.ErrorMessage || "Nu gick något snett", style: "top" }));
            } finally {
                dispatch(setIsLoading(false));
            }
        },
        [dispatch, HandleDownloading, getReqObj, refresh, user]
    );

    // This method is used when posting files etc. We cannot use the same method as above since we need to be able to send a body
    const requestWithBody = useCallback(async (url: string, requestObj: RequestInit, callback?: Function) => {

        dispatch(setIsLoading(true));
        let requestUri = ApiBaseUri + url;

        if(user?.accessToken)
            requestObj.headers = { ...requestObj.headers, Authorization: "Bearer " + user.accessToken};

        if (url.startsWith("http"))
            requestUri = url;


        try {
            let response = await fetch(requestUri, requestObj);

            if(!isSuccessStatusCode(response.status))
                throw response;

            if(callback)
                callback(response.status);

        } catch (error: any) {
            console.log(error);
            dispatch(showAlert({ message: "Nu gick något snett", style: "top" }));
        } finally {
            dispatch(setIsLoading(false));
        }


    }, [dispatch]);

    return {
        requestWithBody,
        request,
        loadingState,
        errorMessage,
    };
};

const isSuccessStatusCode = (statusCode: number) : boolean => {
    return statusCode >= StatusCodes.OK && statusCode <= 299;
}

export const SetBaseUri = () => {
    const host = window.location.host;

    // If we are not running on localhost, we are running on a subdomain
    if (host.indexOf("localhost") < 0)
    {
        ApiBaseUri = `${window.location.protocol}//api.${host}`
    }
};

