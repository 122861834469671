import { routes } from "../Constants/routes";
import { useAppDispatch, useAppSelector } from "../hooks";
import { useNavigate } from "react-router-dom";
import React, { useCallback, useEffect, useRef, useState } from "react";
import ListSearch from "./Helpers/ListSearch";
import { useHelpers } from "../../Utils/helpers";
import { useInternalActionCreator } from "../Actions/InternalActionCreator";
import LoadingIcon from "./Icons/LoadingIcon";
import {
    clearInternalMigrations,
    InternalMigrationListModel,
    storeVonCustomer, VonCustomer,
} from "../Reducers/internalMigrationReducer";
import { setRowCount, setSortChange, setTotalRowCount } from "../Reducers/paginationReducer";
import { MigrationType, SourceType } from "./Enums/CommonEnums";
import Checkbox from "./Helpers/Checkbox";
import { onToggleModal } from "../Reducers/modalReducer";
import { CommentsModal } from "./CommentsModal";
import { modalTypes } from "../Constants/modalTypes";
import { CreateNewMigrationModal } from "./CreateNewMigrationModal";

export default function InternalList() {
    const internalActions = useInternalActionCreator();
    const migrationList = useAppSelector((state) => state.internalMigration.internalMigrationList);
    const migrationCount = useAppSelector((state) => state.internalMigration.migrationCount);
    const navigate = useNavigate();
    const helpers = useHelpers();

    const searches = useAppSelector((state) => state.search.searches)
    const [savedSort, setSavedSort] = useState({ sortOrder: "", key: "" });
    const [sortOrderForIcon, setSortOrderForIcon] = useState(<i></i>);
    const [selectedMigrations, setSelectedMigrations] = useState<string[]>([]);
    const [selectedMigrationId, setMigrationId] = useState<string>("");
    const [offset, setOffset] = useState(0);
    const pagination = useAppSelector((state) => state.pagination);
    
    const activeModal = useAppSelector((state) => state.modal.modal);

    const ref = useRef(null);

    const dispatch = useAppDispatch();

    const showComponent = localStorage.getItem("showComponent_internalList");
    const setShowComponent = (comp: string) => {
        localStorage.setItem("showComponent_internalList", comp);
    }

    useEffect(() => {
        setComponent();
    }, [showComponent, searches["migrations"] && searches["migrations"].length > 2 && searches["migrations"]]);

    useEffect(() => {
        const rowCount = helpers.getStatusForBadge(showComponent ? showComponent : "new", migrationCount);
        if (rowCount !== 0) {
            dispatch(setTotalRowCount(rowCount));
        }
    }, [showComponent, migrationCount]);

    const handleObserver = useCallback((entries) => {
        const target = entries[0];
        const comp = localStorage.getItem("showComponent_internalList");
        if (target.isIntersecting) {
            if (searches.migrations) {
                setOffset(0);
                dispatch(setRowCount(pagination.totalRowCount));
            } else if (pagination.sortChange) {
                dispatch(setSortChange(false))
                internalActions.getPaginatedMigrationsInternal(comp ? comp : "new", savedSort.key ? savedSort.key : "Changed", savedSort.sortOrder ? savedSort.sortOrder : "DESC", searches.migrations && searches.migrations, 0)
            } else {
                dispatch(setRowCount(offset + 25 < pagination.totalRowCount ? offset + 25 : pagination.totalRowCount));
                setOffset(offset + 25);
            }
            if ((migrationList.length + 25 <= offset + 25) && !pagination.sortChange) {
                internalActions.getPaginatedMigrationsInternal(comp ? comp : "new", savedSort.key ? savedSort.key : "Changed", savedSort.sortOrder ? savedSort.sortOrder : "DESC", searches.migrations && searches.migrations, offset)
            }
        }
    }, [migrationList.length, pagination.totalRowCount]);

    useEffect(() => {
        const option = {
            root: null,
            rootMargin: "20px",
            threshold: 1
        };
        const observer = new IntersectionObserver(handleObserver, option);
        if (ref.current) observer.observe(ref.current);
        return () => observer.disconnect();
    }, [handleObserver]);

    function checkStatus(status: number) {
        if (status <= 4)
            return <i className="vismaicon vismaicon-dynamic vismaicon-edit disabled"></i>;
        else if (status > 4 && status < 190 && status !== 9)
            return <LoadingIcon></LoadingIcon>;
        else if (status >= 190 && status <= 250)
            return <i className="vismaicon vismaicon-filled vismaicon-success"></i>;
        else
            return <i className="vismaicon vismaicon-filled vismaicon-error"></i>;
    }

    function setComponent() {
        dispatch(clearInternalMigrations());
        dispatch(setRowCount(25));
        if (showComponent == null) {
            setShowComponent("new");
        }
        const sortFromStorage = localStorage.getItem("sortOrder_internalList");
        if (!sortFromStorage) {
            const sort = { key: "Changed", sortOrder: "DESC" };
            localStorage.setItem("sortOrder_internalList", JSON.stringify(sort));
            setSavedSort(sort);
            getSortTypeForIcon(sort);
        } else {
            const parsedSort = JSON.parse(sortFromStorage);
            setSavedSort(parsedSort);
            getSortTypeForIcon(parsedSort)
        }

        internalActions.getPaginatedMigrationsInternal(showComponent ? showComponent : "new", savedSort.key ? savedSort.key : "Changed", savedSort.sortOrder ? savedSort.sortOrder : "DESC", searches.migrations && searches.migrations, 0);
    }

    function onDelete() {
        internalActions.deleteMigrations(selectedMigrations, () => {
            sortOnColumns(savedSort.key ? savedSort.key : "Changed")
            setSelectedMigrations([])
        })
    }

    function onNavigate(id: string) {
        navigate(routes.internalMigrationDetails(id));
    }

    function sortList() {
        const jsonSort = localStorage.getItem("sortOrder_internalList");
        return jsonSort ? JSON.parse(jsonSort) : { key: "Changed", sortOrder: "DESC" };
    }


    const onSelectMigration = (id: string) => {
        const isSelected = selectedMigrations.includes(id);

        if (isSelected) {
            setSelectedMigrations(prevSelected => prevSelected.filter(item => item !== id));
        } else {
            setSelectedMigrations(prevSelected => [...prevSelected, id]);
        }

        console.log(selectedMigrations)
    };

    function sortOnColumns(key: string) {
        dispatch(setSortChange(true));
        dispatch(clearInternalMigrations());
        const sort = sortList();
        if (key !== sort.key) {
            sort.key = key;
            sort.sortOrder = key === "Changed" ? "DESC" : "ASC";
        }
        else {
            sort.sortOrder = sort.sortOrder === "ASC" ? "DESC" : "ASC";
        }
        localStorage.setItem("sortOrder_internalList", JSON.stringify(sort));
        setSavedSort(sort);
        getSortTypeForIcon(sort);
        internalActions.getPaginatedMigrationsInternal(showComponent ? showComponent : "new", sort.key, sort.sortOrder, searches.migrations && searches.migrations, 0);
    }

    function getSortTypeForIcon(sort: any) {
        const order = sort.sortOrder === "ASC" ? <i className="pull-right vismaicon vismaicon-dynamic vismaicon-sm vismaicon-move-up-circle"></i> : <i className="pull-right vismaicon vismaicon-dynamic vismaicon-sm vismaicon-move-down-circle"></i>;
        setSortOrderForIcon(order);
    }

    function onCheckAllCollaborations() {
        internalActions.checkAllWaitingCollaborations();
        dispatch(clearInternalMigrations());
    }

    function changeTab() {
        setOffset(0);
        setComponent();
    }

    function showComments(id: string) {
        setMigrationId(id);
        dispatch(onToggleModal(modalTypes.commentsModal))
    }

    function createNewManualMigration() {
        dispatch((storeVonCustomer({} as VonCustomer)))
        dispatch(onToggleModal(modalTypes.createNewMigrationModal))
    }

    return <>
    {activeModal === modalTypes.commentsModal && <CommentsModal migrationId={selectedMigrationId}/>}
    {activeModal === modalTypes.createNewMigrationModal && <CreateNewMigrationModal/>}

        <div className="panel">
            <div className="container-padding ">

                <div className="d-flex justify-content-between">
                    <ListSearch placeholder={"Sök på migreringar..."} id={"migrations"} />
                    <button onClick={createNewManualMigration} className="btn btn-primary">Skapa ny migrering</button>
                </div>
                <div className="d-flex justify-content-between">
                    <div className="btn-group btn-group-tabs internal-filters">
                        <button className={`btn ${showComponent === "new" ? "active" : ""}`} onClick={() => {
                            setShowComponent("new");
                            changeTab();
                        }}>Nya <span className="badge">{helpers.getStatusForBadge("new", migrationCount)}</span>
                        </button>
                        <button className={`btn ${showComponent === "collaborations" ? "active" : ""}`} onClick={() => {
                            setShowComponent("collaborations");
                            changeTab();
                        }}>Väntande samarbeten <span className="badge">{helpers.getStatusForBadge("collaborations", migrationCount)}</span>
                        </button>
                        <button className={`btn ${showComponent === "ongoing" ? "active" : ""}`} onClick={() => {
                            setShowComponent("ongoing");
                            changeTab();
                        }}>Pågående <span className="badge">{helpers.getStatusForBadge("ongoing", migrationCount)}</span>
                        </button>
                        <button className={`btn ${showComponent === "manual" ? "active" : ""}`} onClick={() => {
                            setShowComponent("manual");
                            changeTab();
                        }}>Manuellt <span className="badge">{helpers.getStatusForBadge("manual", migrationCount)}</span>
                        </button>
                        <button className={`btn ${showComponent === "success" ? "active" : ""}`} onClick={() => {
                            setShowComponent("success");
                            changeTab();
                        }}>Klart <span className="badge">{helpers.getStatusForBadge("success", migrationCount)}</span>
                        </button>
                        <button className={`btn ${showComponent === "error" ? "active" : ""}`} onClick={() => {
                            setShowComponent("error");
                            changeTab();
                        }}>Misslyckat <span className="badge">{helpers.getStatusForBadge("error", migrationCount)}</span>
                        </button>
                        <button className={`btn ${showComponent === "accept" ? "active" : ""}`} onClick={() => {
                            setShowComponent("accept");
                            changeTab();
                        }}>Acceptera avtal <span className="badge">{helpers.getStatusForBadge("accept", migrationCount)}</span>
                        </button>
                        <button className={`btn ${showComponent === "complete" ? "active" : ""}`} onClick={() => {
                            setShowComponent("complete");
                            changeTab();
                        }}>Slutfört <span className="badge">{helpers.getStatusForBadge("complete", migrationCount)}</span>
                        </button>
                    </div>
                </div>
                <br />
                <div>
                    {showComponent === "collaborations" && migrationList.length > 0 && <button className="btn" onClick={() => onCheckAllCollaborations()}>Kontrollera samarbeten</button>}
                    <div className="table-responsive">
                        <table className="table table-stripe table-regular">
                            <thead>
                                <tr>
                                    <th></th>
                                    {showComponent === "new" && <th style={{ width: "5rem" }}>Guide</th>}
                                    <th style={{ width: "5rem" }}>Detaljer</th>
                                    <th className="cursorpointer" onClick={() => sortOnColumns('AoCustomerName')}>Byrå{savedSort.key === 'AoCustomerName' && sortOrderForIcon}</th>
                                    <th className="cursorpointer" onClick={() => sortOnColumns('OrganizationNumber')}>Orgnummer{savedSort.key === 'OrganizationNumber' && sortOrderForIcon}</th>
                                    <th className="cursorpointer" onClick={() => sortOnColumns('CustomerName')}>Namn{savedSort.key === 'CustomerName' && sortOrderForIcon}</th>
                                    <th>Typ</th>
                                    <th>Status</th>
                                    <th>Källa</th>
                                    <th className="cursorpointer" onClick={() => sortOnColumns('Changed')}>Senast ändrad{savedSort.key === 'Changed' && sortOrderForIcon}</th>
                                    <th>Tilldelad till</th>
                                    <th>Kommentar</th>
                                </tr>
                            </thead>
                            <tbody>
                                {migrationList.length === 0 ? (
                                    <tr>
                                        <td></td>
                                        <td>
                                            <p>Ingen data finns tillgänglig</p>
                                        </td>
                                    </tr>
                                ) : (
                                    migrationList.map((item: InternalMigrationListModel, index: number) => {
                                        return <tr key={index}>
                                            <td className="text-center">
                                                <Checkbox value={selectedMigrations.includes(item.id)}
                                                          customClass={"mb-0"} id={item.id}
                                                          onChange={() => onSelectMigration(item.id)}/>
                                            </td>
                                            {showComponent === "new" && <td className="text-center">
                                                <i className={item.guideCompleted ? "vismaicon vismaicon-filled vismaicon-success" : "vismaicon vismaicon-ok-circle disabled"}></i>
                                            </td>}
                                            <td className="text-center">
                                                <span
                                                    className="cursorpointer"
                                                    onClick={() => onNavigate(item.id)}
                                                >
                                                    {checkStatus(item.status)}
                                                </span>
                                            </td>
                                            <td>{item.aoCustomerName}</td>
                                            <td className="text-nowrap">{item.organizationNumber}</td>
                                            <td>{item.customerName}</td>
                                            <td>{MigrationType[item.type]}</td>
                                            <td>{helpers.getStatusName(item.status)}</td>
                                            <td>{helpers.getSourceType(item.sourceType)}</td>
                                            <td className="text-nowrap">
                                                {helpers.formatDateAndTime(item.changed, false)}
                                            </td>
                                            <td>{item.assignedTo?.name}</td>
                                            <td>
                                                <button type="button" className="btn btn-sm btn-default" onClick={() => showComments(item.id)}>Kommentarer {item.comments > 0 && <span className="badge">{item.comments}</span>}</button>
                                            </td>
                                        </tr>
                                    })
                                )}
                            </tbody>
                        </table>
                        <span ref={ref}></span>
                        <div className="float-right">
                            <button className="btn mb-0" disabled={selectedMigrations.length <= 0} onClick={() => onDelete()}><i className="vismaicon vismaicon-sm vismaicon-delete"></i>Ta bort</button>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </>
}