import { useHttp } from "../../Utils/httpUtils";
import { useAppDispatch } from "../hooks";
import { storeUsers, storeCurrentUser, User, UserRole } from '../Reducers/userReducer';
import { ConnectUser } from "../ConnectUser";


export const useUserActionCreator = () => {
    const dispatch = useAppDispatch();
    const { request } = useHttp();

    const getUsers = (): void => {
        request("/api/user/getUsers", null, (data: User[]) => dispatch(storeUsers(data)));
    };

    const getCurrentUser = (user: ConnectUser): void => {

        const requestInit: RequestInit = {
            method: "get",
            headers: { Authorization: "Bearer " + user.accessToken, 'Content-Type': 'application/json'}
        };

        request(`/api/user/getUser`, requestInit, (data: User) => {
            dispatch(storeCurrentUser(data));
        })
    }

    const deleteUser = (user : User): void => {
        
        const httpRequest = {
            method: 'DELETE'
        }

        request(`/api/user/deleteUser/${user.id}`, httpRequest, getUsers)
    }

    const saveUser = (form : any): void => {
        
        var payload = {
            email: form["email"],
            role:  parseInt(form["role"])
        };

        const httpRequest = {
            method: 'POST',
            body: JSON.stringify(payload)
        }

        request("/api/user/saveUser", httpRequest)
    }

    const updateUserRole = (role : UserRole, userId : string): void => {
        request(`/api/user/updateUserRole/${userId}/${role}`, {method: 'POST'}, () => getUsers())
    }

    return {
        getUsers, deleteUser, saveUser, getCurrentUser, updateUserRole
    }
}