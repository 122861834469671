import { createContext, ReactNode, useState } from "react";
import { useAppDispatch } from "../app/hooks";
import { ConnectUser } from "../app/ConnectUser";
import { authorize as VonConnectAuthorize, getEndSessionLink, getTokenFromRefreshToken } from "../app/VismaConnect";
import { useUserActionCreator } from "../app/Actions/UserActionCreator";
import { showAlert } from "../app/Reducers/alertReducer";


interface IAuthContext {
    user: ConnectUser | null,
    login: (code: string, callback: Function) => void,
    logout: () => void,
    refresh: (user: ConnectUser) => Promise<void>,
}

const AuthContext = createContext({} as IAuthContext);
const LocalStorageAuthorizedUser = "authorizedUser";

export const AuthProvider = ({ children } : { children : ReactNode }) => {

    const [auth, setAuth] = useState<IAuthContext>({} as IAuthContext);
    const dispatch = useAppDispatch();
    const userActions = useUserActionCreator();

    const login = async (code: string, callback: Function) => {
        if (code)
            VonConnectAuthorize(code, (user: ConnectUser) => setUser(user, callback), onLoginFailed);
        else
            await loginWithCachedToken(callback);
    };

    const refresh = async (user: ConnectUser, callback?: Function): Promise<void> => {
        await getTokenFromRefreshToken(user, (user: ConnectUser) => setUser(user, callback), onRefreshFailed);
    }

    const onRefreshFailed = (msg: string, title: string) => {
        onLoginFailed(msg, title);
        unsetUser();
    }

    const onLoginFailed = (msg: string, title: string) => {
        dispatch(showAlert({
            message: msg || "Nu gick något snett", style: "top",
            title: title || "Något blev fel vid inloggning"
        }));
    };

    const logout = () => {
        const endSessionUrl = getEndSessionLink(auth?.user?.idToken);
        localStorage.removeItem("redirectTo");
        unsetUser();
        window.location.href = endSessionUrl;
    };

    const loginWithCachedToken = async (callback: Function) => {
        const authuser: string | null = localStorage.getItem(LocalStorageAuthorizedUser);
        if (authuser) {
            const user: ConnectUser = JSON.parse(authuser)
            if (user?.accessTokenExpire <= Date.now()) {
                await refresh(user, callback);
            } else {
                setUser(user, callback);
            }
        }
    }

    const setUser = (user: ConnectUser, callback?: Function) => {
        localStorage.setItem(LocalStorageAuthorizedUser, JSON.stringify(user))
        setAuth(prevState => ({ ...prevState, user: user}))
        userActions.getCurrentUser(user);
        callback && callback();
    }

    const unsetUser = () => {
        localStorage.removeItem(LocalStorageAuthorizedUser);
        setAuth(prevState => ({
            ...prevState,
            user: null
        }));
    };

    return (
        <AuthContext.Provider value={{...auth, login, logout, refresh}}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;