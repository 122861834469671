import { useAppSelector } from "../hooks";
import { ApiState } from "../Reducers/apiReducer";
import LoadingSpinner from "../Components/Icons/LoadingSpinner"
export default function Loader() {

    const apiState: ApiState = useAppSelector((state) => state.api)

    return (
        <>
            {apiState.isLoading && <div className="text-center"><LoadingSpinner width="48px"/></div>}
        </>
    )
}