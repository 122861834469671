import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Searches {
    [searchInput: string]: any;
}

const initialState: Searches = {
    searches: {}
};

export const searchSlice = createSlice({
    name: 'search',
    initialState,
    reducers: {
        storeSearchTerm: (state, action: PayloadAction<{ searchInput: string | null, id: string }>) => {
            state.searches[action.payload.id] = action.payload.searchInput;
        }
    },
});

export const {storeSearchTerm} = searchSlice.actions;
export default searchSlice.reducer;
