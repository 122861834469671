import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Alert {
    type?: string,
    title?: string,
    message: string,
    style: string
}

export interface AlertState {
    alert : Alert | null 
}

const initialState : AlertState = {alert : null}

export const alertSlice = createSlice({
    name: 'alert',
    initialState,
    reducers: {
        showAlert: (state, action:PayloadAction<Alert>) => {
            state.alert = action.payload
        },
        hideAlert: (state) => {
            state.alert = null;
        }
    },
});

export const { showAlert, hideAlert } = alertSlice.actions;
export default alertSlice.reducer;
