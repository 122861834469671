import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ListObject {
    id: string,
    name: string
}

export interface BankList {
    bankList: Array<ListObject>
}

const initialState : BankList = { bankList: [{ id: "", name: ""}]};

export const bankListSlice = createSlice({
    name: 'bankList',
    initialState,
    reducers: {
        storeBankList: (state, action:PayloadAction<any>) => {
            state.bankList = action.payload;
        }
    },
});

export const { storeBankList } = bankListSlice.actions;
export default bankListSlice.reducer;
