import Modal from "./Helpers/Modal";
import DragAndDrop from "./Helpers/DragAndDrop";
import React from "react";

export function InternalFileUploadModal(props: { migrationId: string }) {

    return <Modal title="Ladda upp filer" hideButtons={true} size="modal-lg">
        <DragAndDrop migrationId={props.migrationId}>
        </DragAndDrop>
    </Modal>;
}