import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ReportRow {
    manualCount : number;
    migrationsCount: number;
    yearMonth: string;
    formatedyearMonth: string;
    percentManual: number;
    percentAutomatic: number;
}

export interface ExceptionRow {
    id: string
    message: string
    stackTrace: string
    userId: string
    customerId: string
    customerName: string
    customerNumber: string
    email: string,
    date: string
}

export interface AdminState {
    reportData : ReportRow[] | null
    exceptions : ExceptionRow[]
}

const initialState: AdminState = { reportData: null, exceptions: []};

export const adminSlice = createSlice({
    name: 'admin',
    initialState,
    reducers: {
        storeReportData: (state, action:PayloadAction<ReportRow[]>) => {
            state.reportData = action.payload
        },
        storeExceptions: (state, action:PayloadAction<ExceptionRow[]>) => {
            state.exceptions = action.payload
        }

    },
});

export const { storeReportData, storeExceptions } = adminSlice.actions;
export default adminSlice.reducer;
