import { useAppDispatch } from "../hooks";
import { useHttp } from "../../Utils/httpUtils";
import {
    addFile,
    deleteUploadedFile,
    doneFile,
    removeFile,
    setFileId,
    setValidated,
    updateProgress
} from "../Reducers/fileUploadReducer";
import { showAlert } from "../Reducers/alertReducer";
import { storeUploadedFiles } from "../Reducers/internalMigrationReducer";

interface IPrepareFileModel {
    fileId: string;
    signedUrl: string;
}

export interface IFileValidationResult {
    success: boolean;
    message: string;
}

export const useFileActionCreator = () => {
    const dispatch = useAppDispatch();
    const {request} = useHttp();

    const deleteFile = (migrationId: string, fileId: string): void => {
        request(`/api/file/deletefile/${migrationId}/${fileId} `, { method: "delete" }, (response: any) => {
            dispatch(deleteUploadedFile(fileId));
            getFiles(migrationId);
        });
    }

    const validateFile = (migrationId: string, fileId: string, file: File): void => {
        request(`/api/file/validatefile/${migrationId}/${fileId} `, { method: "put" }, (validationResult: IFileValidationResult) => {
            dispatch(setValidated({fileId, validationResult}));
            getFiles(migrationId);
        });
    };

    const getFiles = (migrationId: string) => {

        // We need to have a requestObj so we dont get signal: controllerRef.current?.signal so the request becomes abortable
        const requestObj : RequestInit = {
            method: "get"
        };

        request(`/api/file/getfiles/${migrationId}`, requestObj, (data: any) => dispatch(storeUploadedFiles(data)))
    }

    const uploadFileSigned = (migrationId: string, file: File): void => {

        const uploadFileRequest = {
            migrationId,
            fileName: file.name,
            fileSize: file.size,
        }

        dispatch(addFile(file));

        request(`/api/file/getsignedurl`, {body: JSON.stringify(uploadFileRequest), method: "post"}, (response: IPrepareFileModel) =>
        {
            const req = new XMLHttpRequest(); // Initialize request

            dispatch(setFileId({file, id: response.fileId}));

            req.open('put', response.signedUrl);
            req.setRequestHeader("Accept", "application/octet-stream");
            req.setRequestHeader("Content-Type", "application/octet-stream");

            req.upload.addEventListener('progress', e => {
                const percentComplete = (e.loaded / e.total) * 100;
                dispatch(updateProgress({file, progress: percentComplete}));
            })

            req.upload.addEventListener('load', e => {
                // File upload completed successfully
                dispatch(doneFile(file));
                validateFile(migrationId, response.fileId, file);
            });

            req.upload.addEventListener('abort', e => {
                console.log("abort");

            });

            req.upload.addEventListener('error', e => {
                dispatch(showAlert({title: "Fel", message: "Något gick fel vid uppladdning av fil", style: "top", type: "danger"}));
            });

            req.addEventListener('load', function() {
                // When request is complete
                //dispatch(removeFile(file));
                console.log(req.status); // Response status code
                console.log(req.response); // Request response
            })

            req.send(file);
        });
    }

    return {
        uploadFileSigned,
        deleteFile,
        getFiles
    }
}


