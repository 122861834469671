import Modal from "./Helpers/Modal";
import { useAppSelector } from "../hooks";
import { useEffect } from "react";
import { useMigrationActionCreator } from "../Actions/MigrationActionCreator";

export default function ProductInformationModal() {
    const productInformationList = useAppSelector((state) => state.productInformation.productInformationList);
    const migrationActions = useMigrationActionCreator();

    useEffect(() => {
        migrationActions.getProductInformationList()
    }, []);

    return (
        <><Modal title="Produktinformation" size={"modal-xl"} hideButtons={true}>

            <div className="container-padding product-info-card-wrapper">
                {productInformationList && productInformationList.map((product: any, index: number) =>
                    <div key={index} className="mb-8 rounded bg-primary shadow-30 container-padding product-info-card-container">
                        <div className="product-info-card">
                            <h4 className="font-family-ubuntu-semibold font-weight-bold product-info-header">{product.productName}</h4>
                            <p className="product-info-description">{product.productDescription}</p>
                            <div className="product-price-and-website-container">
                                <h4 className="font-family-ubuntu-semibold font-weight-bold product-info-price">{product.productPrice} SEK /kund & månad</h4>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {productInformationList &&
                <div className="d-flex justify-content-center">
                    <section className=" col-4 rounded-10 bg-secondary product-info-website-background"><a className=" text-white product-info-website" href={productInformationList[0].website} target="_blank">Läs mer om eEkonomi</a></section>
                </div>
            }
        </Modal></>
    );
}