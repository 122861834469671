import ladyImage from "./Images/timeAnimationWoman.svg";
export function ImportFilesFinishConfirmation() {
    return (
        <div className="d-flex flex-column align-items-center justify-content-center" >
            <div className="">
                <h1>Nu är du klar!</h1>
            </div>
            <div className="text-center mb-32">
                <p>Dina filer är inskickade och vi kommer att hantera dessa inom 48h (måndag - fredag).
                    När allt är klart <br /> kommer vi att skicka ett mejl till dig.</p>
            </div>
            <div className="d-flex align-items-center flex-column">
                <img src={ladyImage} style={{ maxHeight: "400px", maxWidth: "400px" }} alt="Timelady"></img>
            </div>
        </div>
    )
}