import LogoImg from '../Components/Icons/visma-logo.svg'

export default function Footer() {
    return (
        <footer className="app-footer">
            <div className="pb-12">
                <strong>Om du har frågor kring din flytt är du välkommen att kontakta oss på dataflytt.spcs@visma.com</strong>
            </div>
            <div className="pb-32">
                Copyright &copy; {new Date().getFullYear()} <img src={LogoImg} width="101" alt="Visma"/>
            </div>
        </footer>
    )
}