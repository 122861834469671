export enum MigrationType {
    EAccounting = 1,
    VLS = 2
}

export enum SourceType {
    Admin = 1,
    CsvFiles = 3,
    Fortnox = 5,
    CompactFiles = 6
}

export enum CollaborationStatus {
    manualClientInvitationRequired = 0,
    clientInvited = 1,
    invitationRejected = 2,
    invitationExpired = 3,
    collaborationCreated = 4
}

export enum EaccountingVariant {
    noninteractive = 1,
    accounting = 2,
    invoicingcollaboration = 3,
    standard = 4,
    pro = 5,
    invoicing = 6
}

export enum InvoicePeriod {
    Monthly = 1,
    Quarterly = 3,
    Yearly = 12,
}

export enum InvoiceReceiver
{
    Customer = 1,
    AccountingOffice = 2
}

export enum BookKeepingMethod
{
    Invoicing = 1,
    Cash = 2,
}

export enum MigrationStatus {
    New = 0,

    FilesUploaded = 4,
    WaitingForCollaboration = 8,
    ReuploadFiles = 9,

    ReadyForMigration = 10,
    PrepareFiles = 13,
    TranslateFiles = 15,
    CreateVbAccount = 20,
    CreateVonAccount = 30,
    OrderEAccounting = 40,
    CreateCollaboration = 50,
    PendingCollaboration = 51,
    SaveDataToEAccounting = 70,

    Manual = 190,

    Done = 200,
    OnboardingCompleted = 210,
    GdprClean = 220,
    GdprCleanFailed = 230,
    GdprCleanManual = 240,

    AuthenticationFailed = 900,
    Error = 1000
}