import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Forms {
    [form: string]: any
}

const initialState: Forms = {
    forms: { userForm: { data: { name: "", email: "" } } }
};

export const formSlice = createSlice({
    name: 'form',
    initialState,
    reducers: {
        setFieldValue: (state, action: PayloadAction<{ form: string, field: string, value: any }>) => {
            state.forms[action.payload.form].data[action.payload.field] = action.payload.value;
        }
    }
});


export const { setFieldValue } = formSlice.actions;
export default formSlice.reducer;