import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ActiveModal {
    modal: string,
}

const initialState : ActiveModal = {modal: ""};

export const modalSlice = createSlice({
    name: 'modal',
    initialState,
    reducers: {
        onToggleModal: (state, action: PayloadAction<string>) => {
            state.modal = action.payload
        }
    },
});

export const {onToggleModal} = modalSlice.actions;
export default modalSlice.reducer;
