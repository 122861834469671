import React, { useEffect } from "react";
import { routes } from "../Constants/routes";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../hooks";
import { useMigrationActionCreator } from "../Actions/MigrationActionCreator";
import Balloon from "../Components/Images/balloon.svg"
import RightArrowIcon from "./Icons/RightArrowIcon";

export default function StartPage() {
    const navigate = useNavigate();
    const aoCustomers = useAppSelector(state => state.users.aoCustomers);
    const migrationActions = useMigrationActionCreator();

    useEffect(() => {
        migrationActions.getMigrationsForCurrentUser();
    }, [])

    return (
        <div className="d-flex justify-content-around">
            <section className="hero-card d-flex justify-content-around col-10 flex-wrap">
                <div className="col-md">
                    <div className="hero-card-content" style={{ maxWidth: "800px" }}>
                        <h2 className="card-title">Välkommen till <strong>Visma Spcs Flytthjälp</strong></h2>
                        <br />
                        <p className="mb-32">Här guidar vi dig steg för steg så att du på ett snabbt och enkelt sätt kan genomföra flytten till Visma eEkonomi.</p>
                        <h3 className="bold">Vad följer med?</h3>
                        <p className="mb-32">Uppgifter som kommer att föras över till Visma eEkonomi är företagsuppgifter, kunder, leverantörer, artiklar, obetalda kundfakturor (kundreskontraposter),
                            obetalda leverantörsfakturor (leverantörsreskontraposter) och bokföringen för de sju senaste bokföringsåren.</p>
                        <h3 className="bold">Status</h3>
                        <p >Under Status kan du följa ditt/dina företag och se när flytten är klar. När det är klart hittar du en logg där som visar hur flytten gått. Denna rekommenderar vi att du sparar och går igenom, för att se om det är något du behöver åtgärda i Visma eEkonomi innan du jobbar vidare där.</p>
                        <p>Skulle flytten ta stopp av någon anledning får vi en notifiering och kommer då att titta på ditt ärende. Vid behov kan vi även behöva kontakta dig för att slutföra flytten.</p>
                        <p className="mb-32">OBS! Det är viktigt att du inte jobbar vidare i ditt gamla program när du påbörjat flytten till Visma eEkonomi.</p>
                        <h3 className="bold">Är du redovisningsbyrå och flyttar många kunder på en gång?</h3>
                        <p className="mb-32">Då vill vi tipsa om att använda vår smarta massuppdatering för att få en så effektiv flytt som möjligt. Längst upp vid varje val har du ett fält som du kan använda för att ge alla kunder i listan samma inställning. Exempelvis om du vill bjuda in alla dina kunder till samma variant av Visma eEkonomi,
                            då anger du den varianten längst upp och så slår det igenom på alla kunder i listan.
                            Vill du sedan byta ut valet på någon enstaka kund går det bra att göra det direkt på raden för just den kunden.</p>
                        <div className="btn-block">
                            {aoCustomers && aoCustomers.length > 0 &&
                            <ul className="nav nav-pills nav-pills-lg justify-content-around">
                                <button  onClick={() => navigate(`${routes.summarizedList}`)}><span className="mr-8">Status</span><RightArrowIcon/></button>
                                </ul>
                            }
                        </div>
                    </div>
                </div>
                <div className="mr-48">
                    <div className="w-100 d-flex justify-content-around">
                        <picture className="hero-card-illustration hero-card-illustration-lg" >
                            <img src={Balloon} alt="cool balloon" style={{ height: "900px", width: "900px" }} />
                        </picture>
                    </div>
                </div>
            </section>
        </div>
    );
}