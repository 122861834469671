import React, {useEffect} from "react";
import {useMigrationActionCreator} from "../Actions/MigrationActionCreator";
import {useNavigate, useParams} from "react-router-dom";
import {useAppDispatch, useAppSelector} from "../hooks";
import {AdditionalInfo, setAdditionalValue} from "../Reducers/migrationReducer";
import TextInput from "./Helpers/TextInput";
import LeftArrowIcon from "./Icons/LeftArrowIcon";
import {startGuideDescriptions} from "../Constants/startGuideDescriptions";

const groupBy = (items: Array<AdditionalInfo>, createKey: Function) => {
    return items.reduce((result, item) => {
        const key = createKey(item)
        result[key] = result[key] || [];
        result[key].push(item);

        return result;
    }, Object.create(null));
}

export default function AdditionalInformationContainer() {

    const {id} = useParams();
    const migration = useAppSelector((state) => state.migration.migrationAdditionalInfo);
    const navigate = useNavigate();
    const migrationActions = useMigrationActionCreator();
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (id) {
            migrationActions.getMigrationForAdditionalInfo(id);
        } else {
            navigate(-1);
        }
    }, [])


    if (!migration)
        return <></>

    const groups = groupBy(migration.additionalInformations, (item: AdditionalInfo) => `${item.dataType}:${item.identifier}` || '');

    const onChange = (item: AdditionalInfo) => {
        return (value: string) => dispatch(setAdditionalValue({...item, value}))
    }

    const onSave = () => {
        migrationActions.saveAdditionalInformation(migration, () => navigate(-1));
    }

    return <>
                <div className="d-flex cursorpointer" onClick={() => navigate(-1)}>
                    <LeftArrowIcon /><h3 className="font-weight-bold">Tillbaka</h3>
                </div>
                <form>
                <h2 className="font-weight-bold">{migration.customerName} {migration.organizationNumber}</h2>
                <section className="panel rounded-10 bg-secondary shadow-50 container-padding">
                    <h2 className="font-weight-bold text-white ">{startGuideDescriptions.additionalInformation.header}</h2>
                    <p className="text-white">{startGuideDescriptions.additionalInformation.text}
                        {migration.readonly && <><br/>Den här informationen kan bara ändras om flytten misslyckats.</>}</p>
                </section>
                    <div className="panel py-8">
                        <div className="panel-body">
                            {Object.keys(groups).map((key: string, index: number) =>
                                <div key={index} className="no-top-bottom-padding">
                                    <h4 className="font-weight-bold">{groups[key][0].name || groups[key][0].dataType} {groups[key][0].dataType !== "Company" ? groups[key][0].identifier : ""}</h4>

                                    {groups[key].map((item: AdditionalInfo, index2: number) =>
                                        <div key={index2} className="display-flex align-items-center">
                                            <div className="row">
                                                <div className="col-lg-4">
                                                    <TextInput label={<span>{item.keyName || item.key}{item.required ? <span style={{color: "red"}}>*</span> : null}</span>}
                                                               alignment={"align-items-end"}
                                                               labelWidth={8}
                                                               noAutoComplete={true}
                                                               value={item.value ?? ""}
                                                               elementWidth={8}
                                                               onChange={onChange(item)}
                                                               disabled={migration.readonly}
                                                    />
                                                </div>
                                                <div className="col-lg-4" style={{display: "flex", alignItems:"center"}}>
                                                    {item.error}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    <hr className="mx-24"/>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="panel py-8">
                        <div className="panel-body">
                            <div className="row">
                                <div className="col-md-8">
                                    <button type="button" className="btn" disabled={migration.readonly} onClick={onSave}>Spara</button>
                                    <button type="button" className="btn" onClick={() => navigate(-1)}>Avbryt</button>
                                </div>
                            </div>
                        </div>
                    </div>
            </form>
        </>;
}