import React, {useEffect, useRef, useState} from "react";
import { UserModel } from "../../Reducers/vonReducer";

export default function ButtonGroupWithFilter(props: any) {
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    const [showStartedBy, setShowStartedBy] = useState(false)
    const [showAssignedTo, setShowAssignedTo] = useState(false)

    useEffect(() => {
        document.addEventListener("click", handleClick);

        return () => {
            document.removeEventListener("click", handleClick);
        };
    });

    function handleClick(e: any) {
        const current1 = ref1.current as Node | null
        const current2 = ref2.current as Node | null
        if (current1 && !current1.contains(e.target)) {
            setShowStartedBy(false)
        }
        if (current2 && !current2.contains(e.target)) {
            setShowAssignedTo(false)
        }
    }

    return (
        <span className="btn-filter col-lg-4">
            <div className="btn-group full-width btn-group-primary list-wrap">
                <button type="button" className={`btn full-width ${props.activeButton === "all" ? "active" : ""}`}
                        onClick={() => props.filterMigrations("all")}>Alla
                </button>
                <div ref={ref1} className={`dropdown full-width ${showStartedBy ? "open" : ""}`}>
                    <button type="button" className={`btn btn-group-primary-last full-width  ${props.activeButton === "started" ? "active" : ""}`}
                            onClick={() => {
                                setShowStartedBy(!showStartedBy);
                            }}
                            aria-expanded={showStartedBy}>Startade av
                    </button>
                    <ul className="dropdown-menu" aria-expanded={showStartedBy}
                        aria-hidden={!showStartedBy}>
                       {props.startedByDropdown.map((item: UserModel, index: number) => {
                           return <li key={index} onClick={() => {
                               props.filterMigrations("started", item.fullName);
                               setShowStartedBy(false)
                           }}>
                               <a>{item.fullName}</a>
                           </li>
                       })}
                    </ul>
                </div>
                {/*<div ref={ref2} className={`dropdown full-width ${showAssignedTo ? "open" : ""}`}>*/}
                {/*    <button type="button" className={`btn full-width btn-group-primary-last ${props.activeButton === "assigned" ? "active" : ""}`}*/}
                {/*            onClick={() => {*/}
                {/*                setShowAssignedTo(!showAssignedTo);*/}
                {/*            }}*/}
                {/*            aria-expanded={showAssignedTo}>Tilldelade till*/}
                {/*    </button>*/}
                {/*    <ul className="dropdown-menu" aria-expanded={showAssignedTo}*/}
                {/*        aria-hidden={!showAssignedTo}>*/}
                {/*        {props.assignedToDropdown.map((item: any, index: number) => {*/}
                {/*            return <li key={index} onClick={() => {*/}
                {/*                props.filterMigrations("assigned", item.name);*/}
                {/*                setShowAssignedTo(false)*/}
                {/*            }}>*/}
                {/*                <a>{item.name}</a>*/}
                {/*            </li>*/}
                {/*        })}*/}
                {/*    </ul>*/}
                {/* </div>*/}
            </div>
        </span>
    );

}