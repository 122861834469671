import React from "react";
import { searchSlice } from "../../Reducers/searchReducer";
import { useAppDispatch, useAppSelector } from "../../hooks";

export default function ListSearch(props: any) {
    const dispatch = useAppDispatch();

    const searchActions = searchSlice.actions;
    const searchInput = useAppSelector((state) => state.search.searches[props.id]);

    function onChange(searchInput: any) {
        dispatch(searchActions.storeSearchTerm({ searchInput: searchInput, id: props.id }))
    }

    function onClear() {
        dispatch(searchActions.storeSearchTerm({ searchInput: "", id: props.id }))
    }

    return <>
        <div className="form-group search-group search-group-button">
            <label className="sr-only">Search</label>
            <input className="form-control" type="text" placeholder={props.placeholder || "Sök..."} onChange={(e) => onChange(e.target.value)} value={searchInput || ""}/>
            <span className="clear-search show list-search-clear" onClick={onClear}></span>
        </div>
    </>
}