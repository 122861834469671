import { useEffect, useState } from 'react';
import CustomerInfo from './CustomerInfo';
import { useAppSelector, useAppDispatch } from "../../hooks";
import ConfirmInformation from './ConfirmInformation';
import CompletePage from './CompletePage';
import SMBWoman from '../Images/timeAnimationWoman.svg'
import './SMB.css';
import { useMigrationActionCreator } from "../../Actions/MigrationActionCreator";
import * as formValidationRules from '../../Constants/formValidationRules';
import { StartGuideData } from '../../Actions/MigrationActionCreator';
import { SourceType } from "../Enums/CommonEnums";
import { useParams } from "react-router-dom";
import YogaMan from "../Images/Pictogram-yoga-man.svg";


export default function SMBStartGuide() {
    const [activeStep, setActiveStep] = useState(1);
    const startGuideState = useAppSelector(state => state.smbStartGuide).startGuide;
    const migration = useAppSelector(state => state.migration).migrationDetails
    const migrationActions = useMigrationActionCreator();
    const [validationErrors, setValidationErrors] = useState<{ [key: string]: string }>({});

    const {sourceType} = useParams();

    useEffect(() => {
        if(sourceType) {
            migrationActions.getSmbMigrationDetails(Number(sourceType) as SourceType)
        }
    }, [])

    if(!migration)
        return null

    let stepId = 1;
    const validateCustomerInfoStep = () => {
        const { customerInfo } = startGuideState;
        const errors: { [key: string]: string } = {};

        const formFields = formValidationRules.customerInfoForm;

        formFields.forEach(({ inputId, rule, message }) => {
            const value = customerInfo[inputId as keyof typeof customerInfo];

            if (inputId === "bank") {
                if (!value || !(value as any).id ) {
                    errors[inputId] = message;
                }
            }

            else if(!((rule === 'required' && value) || (new RegExp(rule).test(value.toString())))) {
                errors[inputId] = message;
            }
        });

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const steps = [
        {
            id: stepId++,
            label: 'Kunduppgifter',
            content: <CustomerInfo customerInfo={startGuideState.customerInfo} validationErrors={validationErrors} />,
            complete: (startGuideState.isCustomerInfoComplete),
            validate: validateCustomerInfoStep,
        }
    ];


    steps.push({
        id: stepId++, label: 'Slutför',
        content: <ConfirmInformation startGuide={startGuideState} />,
        complete: activeStep > steps.length,
        validate: () => true
    })

    const maxSteps = steps.length;
    const isFinalStep = maxSteps === activeStep;
    const isAllStepsComplete = steps.slice(0, -1).every((step) => step.complete);
    const isComplete = activeStep > maxSteps;

    const goToStep = (stepId: any) => {
        if (stepId > activeStep) {
            const currentStep = steps[activeStep - 1];
            const isValidStep = currentStep.validate ? currentStep.validate() : true;
            if (!isValidStep) {
                return;
            }
        }

        setValidationErrors({});
        setActiveStep(stepId);
    };

    const goToPreviousStep = () => {
        if (activeStep > 1) {
            setActiveStep(activeStep - 1);
        }
    };

    const goToNextStep = () => {
        const currentStep = steps[activeStep - 1];
        const isValidStep = currentStep.validate ? currentStep.validate() : true;
        if (isValidStep) {
            setActiveStep(activeStep + 1);
        }
    };

    const saveStartGuideData = () => {
        
        const data:StartGuideData= {
            id: migration.id,
            company: {
                bookKeepingMethod: startGuideState.customerInfo.bookKeepingMethod,
                bank: startGuideState.customerInfo.bank,
                vatPeriod: startGuideState.customerInfo.vatPeriod,
                bookKeepingAccount: startGuideState.customerInfo.bookingAccount
            },
            product: startGuideState.product
        }

        migrationActions.saveStartGuideDataSmb(data, goToNextStep)
    };

    const renderStepContent = () => {
        return steps.map((step) =>
            activeStep === step.id ? (
                <div className="w-100" key={step.id}>
                    <div className="panel-heading px-96">
                        <h1 className="panel-title">{step.label}</h1>
                    </div>
                    <div>{step.content}</div>
                </div>
            ) : null
        );
    };

    return (
        <>
            {migration.eAccountingVariant <= 0 ?
                <div className="d-flex justify-content-center position-absolute align-items-center top-0 bottom-0 right-0 left-0">
                    <div className="d-flex justify-content-center col-sm-3 flex-column">
                        <img src={YogaMan} alt="Man in yoga position" />
                        <h3 className="text-center bold">
                            <span>Du behöver köpa eEkonomi innan du kan gå vidare. </span>
                        </h3>
                        <h3 className="text-center bold">
                            <a href="https://vismaspcs.se/produkter/bokforingsprogram/visma-eekonomi" target="_blank" rel="noopener noreferrer">
                            Klicka här.
                            </a>
                        </h3>
                    </div>
                </div>
                :
                <div className="panel d-flex justify-content-around flex-column align-items-center py-48 position-relative m-auto" style={{ width: '70%' }}>
                    <div>
                        <h2 className="pb-48 mb-0 text-center">Överföring till Visma eEkonomi</h2>
                        <div className="panel-body w-100">
                            <div className="stepper px-96" style={{ maxWidth: "100%" }}>
                                <ul>
                                    {steps.map((step, index) => (
                                        <li
                                            key={step.id}
                                            className={`${activeStep === step.id ? 'active' : ''
                                                } ${step.complete ? 'passed' : ''}`}
                                            onClick={() => !isComplete && goToStep(step.id)}
                                            style={{
                                                pointerEvents: steps.slice(0, index).every((step) => step.complete && !isComplete) ? 'auto' : 'none',
                                            }}
                                        >
                                            <div>
                                                <span>{step.label}</span>
                                                <span className="step">Step {step.id}/{maxSteps}</span>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                            {!isComplete && (
                                <div>
                                    <div className="d-flex flex-column align-items-center">
                                        {renderStepContent()}
                                    </div>

                                    <div className="d-flex justify-content-between px-128">
                                        <button
                                            type="button"
                                            className="btn left"
                                            onClick={goToPreviousStep}
                                            disabled={activeStep === 1}
                                        >
                                            Föregående
                                        </button>
                                        <button
                                            type="button"
                                            className={`btn right ${isFinalStep ? 'btn-primary' : ''}`}
                                            onClick={!isFinalStep ? goToNextStep : saveStartGuideData}
                                            disabled={isFinalStep && !isAllStepsComplete}
                                        >
                                            {isFinalStep ? 'Slutför' : 'Nästa'}
                                        </button>
                                    </div>
                                </div>
                            )}
                            {isComplete && <>
                                <CompletePage />
                                <img src={SMBWoman} className='SMBWoman' /></>}
                        </div>
                    </div>
                </div>
            }

        </>
    );
}