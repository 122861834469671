import { useHttp } from "../../Utils/httpUtils";
import { useAppDispatch } from "../hooks";
import { storeCustomers, storeUri } from "../Reducers/fortnoxReducer";

export const useFortnoxActionCreator = () => {
    const dispatch = useAppDispatch();
    const { request } = useHttp();

    const getAuthUri = (isAo: boolean, redirectUri: string): void => {
        request(`/api/migration/getAuthUri?redirectUri=${redirectUri}&isAo=${isAo}`, null, (redirectUri: string) => {
            dispatch(storeUri(redirectUri));
        })
    }

    const getMigrationsForCurrentUser = (): void => {
        request(`/api/migration/getFortnoxMigrationsForUser`, null, (data: any) => dispatch(storeCustomers(data)))
    }

    const getAuthUriReconnect = (migrationId: string, redirectUri: string) => {
        request(`/api/migration/getAuthUri/${migrationId}?redirectUri=${redirectUri}`, null, (redirectUri: string) => {

        window.location.href = redirectUri;
        });
    }

    return {
        getAuthUri,
        getMigrationsForCurrentUser,
        getAuthUriReconnect
    }
}