import { ConnectUser } from "./ConnectUser";
import { useUrlHelper } from "./Constants/urlHelper";
import { Token } from "./Token";

let redirectUri: string = window.location.protocol + "//" + window.location.host + "/login";

const constants = {
    clientId: "migrationtooluserclient",
    scope: "openid email profile migrationtool_api:openid offline_access",
    baseUri: ""
};

export const SetIdentityServer = () =>
{
    const urlHelper = useUrlHelper();
    constants.baseUri = urlHelper.getIdentityServerUrl()
}

const randomString = (length: number) => {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() *
            charactersLength));
    }
    return result;
};

const sha256 = async (plain: string) => {
    const data = new TextEncoder().encode(plain);
    return await window.crypto.subtle.digest('SHA-256', data);
}

const base64urlencode = (buffer: ArrayBuffer) => {
    const u8 = new Uint8Array(buffer);
    let b64 = '';
    for (let i = 0; i < u8.byteLength; i++) {
        b64 += String.fromCharCode(u8[i]);
    }
    return window.btoa(b64).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
}

const pkceChallengeFromVerifier = async (codeVerifier: string) => {
    var hashed = await sha256(codeVerifier)
    var base64 = base64urlencode(hashed);
    return base64;
}

const nonce = randomString(5);
const state: string | null = null;

export const getAutorizationLink = async (): Promise<string> => { 

    const code_verifier = randomString(128);
    const code_challenge = await pkceChallengeFromVerifier(code_verifier);
    sessionStorage.setItem('code_verifier', code_verifier);

    const params = new URLSearchParams();

    params.append("client_id", constants.clientId);
    params.append("redirect_uri", redirectUri);
    params.append("response_type", "code");
    params.append("scope", constants.scope);
    params.append("response_mode", "query");
    params.append("nonce", nonce);
    params.append("code_challenge", code_challenge);
    params.append("code_challenge_method", "S256");
    params.append("prompt" ,"select_account");

    if (state) // is not required
        params.append("state", state);

    return new URL(constants.baseUri + "/connect/authorize?" + params.toString()).toString();
};

const authorizeAsync = async (code: string, setUser: (user:ConnectUser) => void, errorCallback: Function): Promise<void> => {

    const tokenUri = new URL(constants.baseUri + "/connect/token");
    const params = new URLSearchParams();

    const errorMessage = "Inloggningen misslyckades, försök igen senare";
    const code_verifier = sessionStorage.getItem('code_verifier');
    sessionStorage.removeItem('code_verifier');

    if (!code_verifier) {
        errorCallback(errorMessage);
        return;
    }

    params.append('code', code);
    params.append('grant_type', 'authorization_code');
    params.append('client_id', constants.clientId);
    params.append('redirect_uri', redirectUri);
    params.append('code_verifier', code_verifier);

    const request: RequestInit = {
        method: 'POST',
        headers: { "Content-type": "application/x-www-form-urlencoded" },
        body: params
    };

    const tokenResponse = await fetch(tokenUri.toString(), request);
    if (!tokenResponse.ok) {
        errorCallback(errorMessage)
        return;
    }

    const tokenBody: Token = await tokenResponse.json();
    const userRequest: RequestInit = {
        method: 'GET',
        headers: { "Authorization": "Bearer " + tokenBody.access_token }
    };

    const userResponse = await fetch(constants.baseUri + "/connect/userinfo", userRequest);
    if (!userResponse.ok) {
        errorCallback(errorMessage);
        return;
    }

    const user: ConnectUser = await userResponse.json();
    setUserTokens(user, tokenBody);
    setUser(user);
};

export const getTokenFromRefreshToken = async (user:ConnectUser, setUser: (user:ConnectUser) => void, errorCallback: Function): Promise<void> => {

    const tokenUri = new URL(constants.baseUri + "/connect/token");

    const params = new URLSearchParams();

    params.append('grant_type', 'refresh_token');
    params.append("client_id", constants.clientId);
    params.append('refresh_token', user.refreshToken);

    const request: RequestInit = {
        method: 'POST',
        headers: { "Content-type": "application/x-www-form-urlencoded" },
        body: params
    };

    const tokenResponse = await fetch(tokenUri.toString(), request);
    
    if (!tokenResponse.ok) {
        //errorCallback("Vänligen logga in igen", "Din session har gått ut");
        return;
    }
    const tokenBody: Token = await tokenResponse.json();
    
    setUserTokens(user, tokenBody);
    setUser(user);
}

const setUserTokens = (user: ConnectUser, tokenBody: Token) => {
    user.refreshToken = tokenBody.refresh_token;
    user.accessToken = tokenBody.access_token;
    user.idToken = tokenBody.id_token;
    user.accessTokenExpire = Date.now() + (tokenBody.expires_in * 1000);
}

export const authorize = (code: string, callback: (user: ConnectUser) => void, errorCallback: Function) => {
    setTimeout(() => authorizeAsync(code, callback, errorCallback), 0);
};

export const getEndSessionLink = (idToken: string | undefined): string => {
    if (!idToken)
        return "";

    const params = new URLSearchParams();
    const logoutUri = window.location.protocol + "//" + window.location.host;
    params.append('id_token_hint', idToken);
    params.append('post_logout_redirect_uri', logoutUri);
    const url: string = new URL(constants.baseUri + "/connect/endsession?" + params.toString()).toString();

    return url;
};