import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
    BookKeepingMethod,
    CollaborationStatus,
    EaccountingVariant,
    InvoicePeriod,
    InvoiceReceiver
} from "../Components/Enums/CommonEnums";
import { AoMigration } from "./userReducer";
import { InvoicingInformation, PrimaryContact } from "../Actions/MigrationActionCreator";

//This is same as in backend class MigrationViewModel
export interface StartGuideListModel {
    migrations: StartGuideObject[],
    collaborationItems: StartGuideObject[],
    canCreateCollaborations: boolean,
    vonEmails: string[],
    notAllowedMigrations: StartGuideObject[],
    currentPage: number,
    loading: boolean,
    selectedClients: string[],
}

export interface CollaborationOption {
    consultantEmail: string;
    eaccountingVariant: EaccountingVariant;
    invoiceReceiver: InvoiceReceiver;
    period: InvoicePeriod;
    primaryContact: PrimaryContact;
    invoicingInformation: InvoicingInformation;
}

interface CollaborationConfig {
    canCreateNonInteractiveCollaboration: boolean
    hasAlreadyCollaboration: boolean
    inviteWillBeSent: boolean
    canCreateCollaboration: boolean
    hasMultipleCustomers: boolean
}

export interface Bank {
    id: string;
    name: string;
}

export interface Company {
    bookKeepingMethod: BookKeepingMethod;
    bank: Bank;
    vatPeriod: string;
    bookKeepingAccount: number;
}

export interface StartGuideObject {
    aoMigration: AoMigration;
    organizationNumber: string;
    customerName: string;
    id: string,
    collaborationStatus: CollaborationStatus
    eaConfiguration: {
        company: Company,
        collaborationOptions: CollaborationOption
    },
    collaborationConfig: CollaborationConfig
}

const initialState: StartGuideListModel = {
    collaborationItems: [],
    migrations: [],
    canCreateCollaborations: true,
    vonEmails: [],
    notAllowedMigrations: [],
    currentPage: 1,
    loading: true,
    selectedClients: []
};

function setProperty<T>(obj: Record<string, T>, key: string, value: T) {
    obj[key] = value;
}

interface UpdateCollaboration {
    id: string,
    field: string;
    value: any;
}

export const startGuideSlice = createSlice({
    name: 'startGuide',
    initialState,
    reducers: {
        storeStartGuideData: (state, action: PayloadAction<StartGuideListModel>) => {
            state.collaborationItems = action.payload.collaborationItems;
            state.migrations = action.payload.migrations;
            state.vonEmails = action.payload.vonEmails;
            state.canCreateCollaborations = action.payload.canCreateCollaborations;

            if (state.selectedClients.length > 0) { 
            state.collaborationItems = state.collaborationItems.filter(collaborationItem =>
                state.selectedClients.includes(collaborationItem.id)
            );
            
            state.migrations = state.migrations.filter(migrationItem => 
                state.selectedClients.includes(migrationItem.id))
        }

            state.loading = false;
        },
        setCollaborationValue: (state, action: PayloadAction<UpdateCollaboration>) => {
            const migration = state.collaborationItems.find(mig => mig.id === action.payload.id) as StartGuideObject;
            setProperty(migration.eaConfiguration.collaborationOptions, action.payload.field, action.payload.value);
        },
        setCollaborationInvoicingInformation: (state, action: PayloadAction<UpdateCollaboration>) => {
            const migration = state.collaborationItems.find(mig => mig.id === action.payload.id) as StartGuideObject;
            setProperty(migration.eaConfiguration.collaborationOptions.invoicingInformation, action.payload.field, action.payload.value);
        },
        setPrimaryContactInformation: (state, action: PayloadAction<UpdateCollaboration>) => {
            const migration = state.collaborationItems.find(mig => mig.id === action.payload.id) as StartGuideObject;
            setProperty(migration.eaConfiguration.collaborationOptions.primaryContact, action.payload.field, action.payload.value);
        },
        setCompanyValue: (state, action: PayloadAction<{ id: string, field: string, value: any }>) => {
            const index = state.migrations.findIndex(mig => mig.id === action.payload.id);
            setProperty(state.migrations[index].eaConfiguration.company, action.payload.field, action.payload.value);
        },
        setPageNumber: (state, action: PayloadAction<number>) => {
            state.currentPage = action.payload;
        },
        storeVonEmails: (state, action: PayloadAction<string[]>) => {
            state.vonEmails = action.payload
        },
        setSelectedClients: (state, action: PayloadAction<string[]>) => {
            state.selectedClients = action.payload
        }
    },
});

export const {
    setCollaborationValue,
    setCompanyValue,
    storeVonEmails,
    storeStartGuideData,
    setCollaborationInvoicingInformation,
    setPrimaryContactInformation,
    setPageNumber,
    setSelectedClients
} = startGuideSlice.actions;
export default startGuideSlice.reducer;
