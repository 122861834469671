import { useNavigate } from 'react-router-dom';
import { routes } from '../../Constants/routes';
import RightArrowIcon from '../Icons/RightArrowIcon';
import { useHelpers } from "../../../Utils/helpers";

export default function CSVIntroPage() {
    
    const navigate = useNavigate();

    const helper = useHelpers();

    function startCsv() {
        navigate(`${routes.csvImport}`)
    }

    return (
        <div className="d-flex justify-content-around">
            <div className='hero-card'>
                <div className='py-48 px-32 w-100'>
                    <div className="row align-items-center justify-content-around">
                        <div className='mb-32'>
                            <div>
                                <h2 className="card-title">Välkommen till <strong>Visma Spcs Flytthjälp</strong></h2>
                                <p>Du kommer nu att påbörja en beställning av flytt till Visma eEkonomi för följande företag: <strong>{helper.authorizedCompanyName()}</strong></p>

                                <p><b>Är det fel företag?</b> <a href="#" onClick={() => helper.changeAuthCompany()}>Byt företag här</a> </p>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-around">
                        <div className="nav nav-pills nav-pills-lg">
                            <button onClick={() => startCsv()} className="btn btn-primary " >Kör igång</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

