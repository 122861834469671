import BaseSearch from "./Helpers/BaseSearch";
import DropDown from "./Helpers/Dropdown";
import { startGuideDropdowns } from "../Constants/dropdownLists";
import { setCompanyValue, StartGuideObject } from "../Reducers/startguideReducer";
import ConfirmStartGuideDataModal from "./ConfirmStartGuideDataModal";
import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector} from "../hooks";
import { useMigrationActionCreator } from "../Actions/MigrationActionCreator";
import * as formValidationRules from "../Constants/formValidationRules";
import { onToggleModal } from "../Reducers/modalReducer";
import { EAccountingDataProps } from "./EAccountingData";

export function EaccountingBankAndVat(props: EAccountingDataProps) {
    const dispatch = useAppDispatch();
    const bankListState = useAppSelector(state => state.bankList.bankList);
    const dropdownState = useAppSelector(state => state.dropdown);
    const migrationActions = useMigrationActionCreator();
    const activeModal = useAppSelector((state) => state.modal.modal);

    useEffect(() => {
        migrationActions.getAllBanks();
    }, [])

    function onSaveClick() {
        const res = migrationActions.validateStartGuideData(props.migrations, formValidationRules.eAccountingForm);

        if(!res) {
            dispatch(onToggleModal("confirmSaveStartGuide"));
        }
        else {
            console.log("Validation failed", res);
        }
    }

    function getBankValue(id:any){
        return bankListState.find(x => x.id === id);
    }

    return <>
        <div className="panel-body no-top-bottom-padding">
            <div className="row display-flex align-items-center">
                        <span className="col-2">
                            <div>
                                <p className="font-weight-bold">{props.description}</p>
                            </div>
                        </span>
                <div className="action col-5">
                    <DropDown list={bankListState} label={"Bank"}
                              alignment={"align-items-end"}
                              value={dropdownState.inputs["bank"] && dropdownState.inputs["bank"].id}
                              onChange={(value: any) => props.changeAllDropdownValues(getBankValue(value), "bank")}
                              blankOption={true} elementWidth={8} />
                </div>
                <div className="action col-5">
                    <DropDown list={startGuideDropdowns.vatPeriod} label={"Momsperiod"}
                              alignment={"align-items-end"}
                              value={dropdownState.inputs["vatPeriod"]}
                              onChange={(value: any) => props.changeAllDropdownValues(value, "vatPeriod")}
                              blankOption={true} elementWidth={8} />
                </div>
            </div>
        </div>
        <hr className="first-line"></hr>
        {props.migrations.map((migration: StartGuideObject, index: number) => {
            return <span key={index}>
                        {index !== 0 &&<hr className="mx-24"></hr>}
                <div className="panel-body no-top-bottom-padding">
                            <div className="row display-flex align-items-center">
                                <span className="col-2">
                                    <div>
                                        <p className="font-weight-bold">{migration.customerName}</p>
                                        <p className="font-weight-bold">{migration.organizationNumber}</p>
                                    </div>
                                </span>
                                <div className="action col-5">
                                    <DropDown list={bankListState} label={"Bank *"}
                                              alignment={"align-items-end"}
                                              value={migration.eaConfiguration.company.bank?.id}
                                              onChange={(value: any) => dispatch(setCompanyValue({
                                                  id: migration.id,
                                                  field: "bank",
                                                  value : getBankValue(value)
                                              }))}
                                              blankOption={true} elementWidth={8} />
                                </div>
                                <div className="action col-5">
                                    <DropDown list={startGuideDropdowns.vatPeriod} label={"Momsperiod *"}
                                              alignment={"align-items-end"}
                                              value={migration.eaConfiguration.company.vatPeriod}
                                              onChange={(value: any) => dispatch(setCompanyValue({
                                                  id: migration.id,
                                                  field: "vatPeriod",
                                                  value
                                              }))}
                                              blankOption={true} elementWidth={8} />
                                </div>

                            </div>
                        </div>
                    </span>
        })}
        <div className="d-flex justify-content-end mr-32">
            <button type="button" className="btn btn-primary" onClick={() => onSaveClick()}>Spara</button>
        </div>
        {activeModal === "confirmSaveStartGuide" && <ConfirmStartGuideDataModal handleCollaborations={false}
                                                                                collaborations={[]}
                                                                                migrations={props.migrations} />}
    </>
}